<template>
  <div>
    <span>Overzicht</span>

    <base-alert variant="error" v-if="error">{{ error }}</base-alert>

    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Naam</th>
          <th>Key</th>
          <th>Globaal</th>
          <th>Prive</th>
          <th>Frontpage</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, id) in data" :key="item.id" :class="{'process': item.process}">
          <td>{{ item.id || id}}</td>
          <td>{{ item.name}}</td>
          <td>{{ item.key}}</td>
          <td>{{ item.global}}</td>
          <td>{{ item.private}}</td>
          <td>{{ item.frontpage}}</td>
          <td class="action">
            <base-button :disabled="item.process" :to="{name: 'Admin_Category_Update', params: { id: item.id }}">Edit</base-button>
            <base-button :variant="'delete'" :disabled="item.process" @click="deleteItem(item.id, id)">Delete</base-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      data: [],
      error: null
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$services['category'].getAll().then(r => {
        r.map(item => item.process = false);

        this.data = [ ...r ];
        this.data.sort((a,b) => (a.name > b.name) ? 1 : -1)
      });
    },
    deleteItem(id, index) {
      this.data[index].process = true;
      this.error = null;
      if (id) {
        this.$services['category'].delete(id).then(this.load)
      } else {
        this.data[index].process = false;
        this.error = `Cannot find ID ${id}`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  @apply min-w-full divide-y divide-gray-200;

  thead {
    @apply bg-gray-50;

    th {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }

  tbody {
    tr {
      &.process {
        td {
          @apply bg-red-500 text-white #{!important};
        }
      }
      td {
        @apply px-3 py-2 whitespace-nowrap text-sm text-gray-900;
      }
      &:nth-child(2n) {
        @apply bg-gray-50;
      }
      &:hover {
        @apply bg-yellow-100;
      }
    }
  }
}

</style>