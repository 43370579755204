import Vue from 'vue'
import VueRouter from "vue-router";
import store from "@/store";

import Home from "@/views/Home";
import Admin from "@/views/Admin";

import Admin_Daycounter from "@/views/admin/Daycounter";
import Admin_Daycounter_List from "@/views/admin/daycounter/List";
import Admin_Daycounter_Form from "@/views/admin/daycounter/Form";

import Admin_Category from "@/views/admin/Category";
import Admin_Category_List from "@/views/admin/category/List";
import Admin_Category_Form from "@/views/admin/category/Form";


import Frontpage from "@/views/Frontpage";
import Login from "@/views/Login";
import PasswordForgotten from "@/views/PasswordForgotten";

import Account from "@/views/Account";
import AccountDaycounter from "@/views/account/Daycounter";
import AccountSettings from "@/views/account/Settings";
import Calculate from "@/views/Calculate";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      name: 'Frontpage'
    },
    children: [
      {
        path: '',
        name: 'Frontpage',
        component: Frontpage
      },
      {
        path: 'berekenen',
        name: 'Calculate',
        component: Calculate
      },
      {
        path: 'dagenteller/:firestore_id(.*)',
        name: 'Daycounter',
        component: Calculate
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
          if (store.getters['user/isAuthenticated']) {
            next({ name: 'Account' });
          } else {
            next();
          }
        }
      },
      {
        path: 'password_forgotten',
        name: 'PasswordForgotten',
        component: PasswordForgotten,
        beforeEnter: (to, from, next) => {
          if (store.getters['user/isAuthenticated']) {
            next({ name: 'Account' });
          } else {
            next();
          }
        }
      },
      {
        path: '/account',
        name: 'Account',
        component: Account,
        redirect: {
          name: 'Account_Daycounter'
        },
        children: [
          {
            path: 'daycounter',
            name: 'Account_Daycounter',
            component: AccountDaycounter
          },
          {
            path: 'settings',
            name: 'Account_Settings',
            component: AccountSettings
          }
        ]
      },
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: {
      name: 'Admin_Daycounter'
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['user/isAuthenticated'] || !store.getters['user/isAdmin']) {
        next({ name: 'Login' });
      } else {
        next();
      }
    },
    children: [
      {
        path: 'daycounter',
        name: 'Admin_Daycounter',
        component: Admin_Daycounter,
        redirect: {
          name: 'Admin_Daycounter_List',
        },
        children: [
          {
            path: '',
            name: 'Admin_Daycounter_List',
            component: Admin_Daycounter_List
          },
          {
            path: 'create',
            name: 'Admin_Daycounter_Create',
            component: Admin_Daycounter_Form
          },
          {
            path: 'update/:id(.*)',
            name: 'Admin_Daycounter_Update',
            component: Admin_Daycounter_Form
          }
        ]
      },
      {
        path: 'category',
        name: 'Admin_Category',
        component: Admin_Category,
        redirect: {
          name: 'Admin_Category_List',
        },
        children: [
          {
            path: '',
            name: 'Admin_Category_List',
            component: Admin_Category_List
          },
          {
            path: 'create',
            name: 'Admin_Category_Create',
            component: Admin_Category_Form
          },
          {
            path: 'update/:id(.*)',
            name: 'Admin_Category_Update',
            component: Admin_Category_Form
          }
        ]
      }
    ]
  }

]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
