<template>
  <div class="time">
    <base-button @click="setNow" block variant="info" class="mb-2">Huidige tijd</base-button>
    <div>
      <div>

        <div class="rounded border border-gray-300 bg-white grid gap-1 grid-cols-9 mb-2 cursor-pointer" @click="setTime('morning', 6, 0)">
          <span class="col-span-4 py-1 text-right">06:00</span>
          <span class="col-span-5 rounded-r py-1.5 p-1 bg-gray-600 self-center text-sm text-white">Ochtend</span>
        </div>

        <div class="rounded border border-gray-300 bg-white grid gap-1 grid-cols-9 mb-2 cursor-pointer" @click="setTime('afternoon', 12, 0)">
          <span class="col-span-4 py-1 text-right">12:00</span>
          <span class="col-span-5 rounded-r py-1.5 p-1 bg-gray-600 self-center text-sm text-white">Middag</span>
        </div>

        <div class="rounded border border-gray-300 bg-white grid gap-1 grid-cols-9 mb-2 cursor-pointer" @click="setTime('evening', 18, 0)">
          <span class="col-span-4 py-1 text-right">18:00</span>
          <span class="col-span-5 rounded-r py-1.5 p-1 bg-gray-600 self-center text-sm text-white">Avond</span>
        </div>

        <div class="rounded border border-gray-300 bg-white grid gap-1 grid-cols-9 mb-2 cursor-pointer" @click="setTime('night', 0, 0)">
          <span class="col-span-4 py-1 text-right">00:00</span>
          <span class="col-span-5 rounded-r py-1.5 p-1 bg-gray-600 self-center text-sm text-white">Nacht</span>
        </div>


      </div>
    </div>

    <div class="grid-cols-11 gap-0 grid align-middle">
      <div class="col-span-5">
        <base-select :data="getHours()" id="value" label="text" v-model="hour"></base-select>
      </div>
      <div class="col-span-1 text-center self-center">:</div>
      <div class="col-span-5">
        <base-select :data="getMinutes()" id="value" label="text" v-model="minute"></base-select>
      </div>
    </div>

    <base-button block class="mt-3" variant="success" @click="setDate">Klaar</base-button>
  </div>
</template>

<script>
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import setMilliseconds from "date-fns/setMilliseconds";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import {formatDate} from "@/plugins";

export default {
  name: "DagenTellerTime",
  props: ["date", "isNow"],
  data() {
    return {
      useDate: null,
      isThisNow: true,
      hour: 0,
      minute: 0,
      timeType: "none"
    };
  },
  methods: {
    setNow() {
      this.$emit("set-time", {date: new Date(), format: "L LT", isNow: true});
    },
    setTime(timeType, hour, minute) {
      this.isThisNow = false;
      this.timeType = timeType;
      this.hour = hour;
      this.minute = minute;

      this.setDate();
    },
    setDate(stayOpen) {
      let date = formatDate(this.useDate);
      date = setHours(date, this.hour);
      date = setMinutes(date, this.minute);
      date = setSeconds(date, 0);
      date = setMilliseconds(date, 0);
      this.$emit("set-time", { date: date, isNow: false, close: stayOpen || false });
    },
    setCustomTime() {
      this.isThisNow = false;
      this.timeType = "custom";

      if (this.hour === null) {
        this.hour = 0;
      }
      if (this.minute === null) {
        this.minute = 0;
      }
      this.setDate(true);
    },
    isTimeType(type) {
      return this.timeType === type ? "primary" : "secondary";
    },
    getHours() {
      let hours = [];
      let i = 0;
      while (i < 24) {
        hours.push({ value: i, text: i });
        i++;
      }
      return hours;
    },
    getMinutes() {
      let minutes = [];
      let i = 0;
      while (i < 60) {
        minutes.push({ value: i, text: i < 10 ? "0" + i : i });
        i++;
      }
      return minutes;
    }
  },
  mounted() {
    this.useDate = this.date || null;
  },
  created() {
    this.useDate = this.date;
    this.hour = getHours(this.date) || 0;
    this.minute = getMinutes(this.date) || 0;
  },
  watch: {
    isNow(to) {
      this.isThisNow = to;
    },
    date(to) {
      this.useDate = to || null;
      if (to && this.isThisNow) {
        this.hour = getHours(to);
        this.minute = getMinutes(to);
      }
    }
  }
};
</script>