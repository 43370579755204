<template>
  <div :class="{'error':hasError}">
    <label :for="id" v-if="$slots.default">
      {{ id }}
      <slot />
    </label>
    <div class="mt-1">
      <select :id="id" :multiple="isMultiple" :size="isSize" :required="isRequired" v-on:[events]="handler" v-model="v" v-bind="$attrs"
              class="block text-sm w-full shadow-sm border border-gray-300 rounded-md px-3 py-2" :class="[extra_class]">
        <option v-for="d in getData" :key="d.id" :value="d.id">{{ d.value }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    value: {
      required: false,
      type: [ String, Boolean, Number, Array ]
    },
    required: {
      required: false,
      default: false,
      type: [ String, Boolean ]
    },
    multiple: {
      required: false,
      default: false,
      type: [ String, Boolean ]
    },
    size: {
      required: false,
      default: 3,
      type: [ Number, String],
    },
    events: {
      required: false,
      default: null,
      type: Object,
    },
    data: {
      required: true,
      type: Array
    },
    id: {
      required: false,
      type: String,
      default: 'id'
    },
    name: {
      required: false,
      type: String
    },
    label: {
      required: false,
      default: 'value'
    },
    extra_class: {
      required: false,
      default: ''
    },
    error: {
      required: false,
      type: Object
    }
  },
  computed: {
    isRequired() {
      return this.required === '' || this.required === true || this.required === 'required' || this.required === 'true';
    },
    isMultiple() {
      return this.multiple === '' || this.multiple === true || this.multiple === 'multiple' || this.multiple === 'true';
    },
    isSize() {
      return (this.isMultiple) ? this.size : 1;
    },
    getData() {
      return this.data.map(d => {
        return { id: d[this.id], value: d[this.label]}
      })
    },
    hasError() {
      return (this?.name && this.error !== null && Object.prototype.hasOwnProperty.call(this.error, this.name) && this.error[this.name] === true);
    }
  },
  data() {
    return {
      v: null,
    }
  },
  methods: {
    handler(event) {
      console.log(event);
    },
    updateValue(event) {
      this.$emit('input',event.target.value);
    }
  },
  watch: {
    v: {
      immediate: true,
      handler(to) {
        this.$emit('input', to);
      }
    },
    value: {
      immediate: true,
      handler(to) {
        this.v = to;
      }
    }
  }
}
</script>

<style scoped lang="scss">
select {
  &:focus {
    @apply ring-indigo-500 border-indigo-500;
  }
}
label {
  @apply block text-sm font-medium text-gray-700;
}
.error {
  label {
    @apply text-red-600;
  }
  select {
    @apply border-red-600;
  }
}
</style>