<template>
  <div class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 divide-x" v-if="!loading">
    <div class="order-none">

      <div v-if="false">
        <span>Dit deze van jou?</span>
        <span v-if="isAnonymous">Anoniem {{ userId }}</span>
        <span v-if="isOfCurrentUser">Van mij</span>
        <span v-if="isAuthenticated">Ingelogd</span>
      </div>

      <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Maak een DagenTeller</div>
      <p class="text-gray-400 text-sm text-center">Begin met het samenstellen van uw dagenteller, begin met de startdatum</p>

      <form-calculator class="mt-6" :value="data" :loading="loading" @submit="calculate" />

    </div>
    <div class="calculation">
      <div class="w-4/5 m-auto divide-y">

        <div class="pb-6" v-if="calculation.type === false">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Berekening</div>
          <p class="text-gray-400 text-sm text-center">Bekijk de verschillen per tijdseenheid.</p>

          <div class="mt-6">

            <div class="text-center text-lg mb-6">
              <div class="grid grid-cols-3 gap-5">
                <div class="col-span-2 text-right">{{ calculation.start.date | format('PPPP')}}</div>
                <div class="col-span-1 text-left" :class="{'text-gray-300':zeroTime(calculation.start.date, 'start?!')}">{{ calculation.start.date | format('pp')}}</div>
              </div>
              <font-awesome-icon icon="sort" class="text-orange-400" v-if="!isLive" />
              <font-awesome-icon icon="caret-up" class="text-red-500" v-if="isLive && diff.seconds < 0" />
              <font-awesome-icon icon="caret-down" class="text-green-600" v-if="isLive && diff.seconds >= 0" />
              <div class="grid grid-cols-3 gap-5">
                <div class="col-span-2 text-right">{{ calculation.end.date | format('PPPP')}}</div>
                <div class="col-span-1 text-left" :class="{'text-gray-300':zeroTime(calculation.end.date, 'end')}">{{ calculation.end.date | format('pp')}}</div>
              </div>
            </div>

            <div v-for="type in types" :key="type" class="bg-white border border-gray-200 rounded shadow flex mb-2">
              <div class="text-right flex-grow text-lg leading-6 py-2 px-3">{{ diff[type] | abs | number(true) }}</div>
              <div class="bg-slate-200 w-24 py-2 px-3 text-slate-600">{{ type | timeLabel(Math.abs(diff[type])) }}</div>
              <div class="bg-orange-400 w-18 rounded-r py-2 px-3 text-white" v-if="!isLive">verschil</div>
              <div class="bg-red-400 w-18 rounded-r py-2 px-3 text-white" v-if="isLive && diff.seconds < 0">geleden</div>
              <div class="bg-green-500 w-18 rounded-r py-2 px-3 text-white" v-if="isLive && diff.seconds >= 0">te gaan</div>
            </div>

            <div class="text-right text-sm flex justify-center align-middle mt-6">
              <div class="text-gray-400 mr-1" @click="diff_exact = false;">Toon exact getal </div>
              <div>
                <base-toggle v-model="diff_exact" off="slate-200" on="sky-400" size="sm" />
              </div>
              <div class="text-gray-400 ml-1" @click="diff_exact = true;">Toon komma getal </div>
            </div>

          </div>
        </div>

        <div class="pb-6" v-if="calculation.type === true">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Berekening</div>
          <p class="text-gray-400 text-sm text-center">Hier zie je een opsomming van jouw aantallen en de datum als het resultaat.</p>

          <div class="mt-6 text-lg">
            <div class="grid grid-cols-5 gap-5">
              <div class="col-span-4 text-right">{{ calculation.start.date | format('PPPP')}}</div>
              <div class="col-span-1 text-left" :class="{'text-gray-300':zeroTime(calculation.start.date)}">{{ calculation.start.date | format('pp')}}</div>
            </div>
            <div v-for="(amount, id) in calculateAmounts" :key="id">
              <div class="grid grid-cols-5 gap-5 mb-3 mt-1">
                <div class="col-span-4 text-right" :class="{'text-green-600':!amount.subtract,'text-red-600':amount.subtract}">
                  {{ amount.value | number }} {{ amount.type | timeLabel(amount.value) }}
                </div>
                <div class="col-span-1 text-center">
                  <font-awesome-icon icon="plus" class="text-green-500 text-opacity-25" v-if="!amount.subtract" />
                  <font-awesome-icon icon="minus" class="text-red-500 text-opacity-25" v-if="amount.subtract" />
                </div>
              </div>
              <div class="bg-gray-300 w-2/3 h-0.5 mx-auto"></div>
              <div class="grid grid-cols-5 gap-5 text-gray-500" :class="{'text-xl text-gray-900':calculateAmounts.length === id + 1}" v-if="isValidDate(amount.date)">
                <div class="col-span-4 text-right pt-2">{{ amount.date | format('PPPP')}}</div>
                <div class="col-span-1 text-left pt-2" :class="{'text-gray-300':zeroTime(amount.date)}">{{ amount.date | format('pp')}}</div>
              </div>

              <div class="grid grid-cols-5 gap-5 text-gray-500" :class="{'text-xl text-gray-900':calculateAmounts.length === id + 1}" v-if="!isValidDate(amount.date)">
                <div class="col-span-4 text-right text-base pt-2 text-red-400">Ongeldige berekening</div>
              </div>

              <div class="grid grid-cols-5 gap-5 text-gray-500" :class="{'text-xl text-gray-900':calculateAmounts.length === id + 1}" v-if="!isValidDate(amount.date)">
                <div class="col-span-5 text-sm text-white p-2 border-red-400 border mt-3 bg-red-500 rounded">Datum kan niet berekend worden, dit kan te maken hebben dat je een te groot aantal wil op- of aftrekken. Er is een maximum bereik die we kunnen berekenen.</div>
              </div>

            </div>
          </div>
        </div>

        <div class="py-6" v-if="calculation.type !== true">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Totaal verschil</div>
          <p class="text-gray-400 text-sm text-center">Exact het verschil berekend tussen de 2 datums.</p>

          <div v-if="!noDiff" class="mt-6 bg-white border border-gray-200 rounded shadow mb-2 center text-lg text-center leading-6 py-2 px-3">
          <template v-for="type in reversedTypes">
            <span v-if="precise[type]" :key="type">
              {{ precise[type] }} {{ type | timeLabel(precise[type]) }}{{ getSpacer(type) }}
            </span>
          </template>
          </div>
          <div v-if="noDiff" class="mt-6 text-orange-600 text-center text-lg">
            <p>De opgegeven datums zijn gelijk, er is geen tijdsverschil.</p>
          </div>

        </div>

        <div class="py-6">
          <div ref="buymeacoffee"></div>
        </div>
      </div>

    </div>
    <div class="order-last">
      <div class="w-4/5 m-auto divide-y">
        <div class="pb-6">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-3/4 text-center pb-2 mx-auto">Deel de dagenteller</div>
          <p class="text-gray-400 text-sm text-center">Klik op de link hieronder om het te kopieren en plakken. Deel de link met je vrienden, familie of post hem op social media.</p>
          <div @click="copyShare" class="col-span-1 cursor-pointer flex border  rounded mt-6 transition ease-in-out delay-250" :class="{'border-green-500':copied,'border-slate-200':!copied}">
            <div class=" py-3 px-4 flex-shink transition ease-in-out delay-250" :class="{'bg-green-500':copied,'bg-slate-200':!copied}">
              <font-awesome-icon class=" transition ease-in-out delay-250" :class="{'text-white':copied,'text-slate-500':!copied}" icon="share-nodes" />
            </div>
            <div class="inline-block flex-grow">
              <input
                  class="w-full border-none leading-8 focus:outline-none focus:ring-0 cursor-pointer"
                  type="text"
                  :value="`${originUrl}/dagenteller/${firestoreId}`"
                  readonly
                  @focus="$event.target.select()"
                  ref="share"
              />
            </div>
          </div>
          <div class="text-right text-xs text-green-500 mt-1" v-if="copied">gekopieerd naar jouw klembord!</div>
        </div>

        <div class="py-6" v-if="isAuthenticated">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-3/4 text-center pb-2 mx-auto">Bewaar de dagenteller</div>
          <p class="text-gray-400 text-sm text-center">De dagenteller vaker terug kijken? Bewaar hem in je account.</p>

          <form @submit="saveDaycounter" class="mt-6">
            <base-input v-model="daycounter.name" extra_class="p-2">Naam</base-input>
            <base-textarea class="mt-3" v-model="daycounter.description" extra_class="p-2">Beschrijving</base-textarea>

            <div class="mt-3">
              <div class="text-sm text-gray-600">
                Categorie
                <span v-show="daycounter.categories.length" @click="clearCategories" class="text-xs cursor-pointer text-sky-600 ml-3 underline">Wis alles</span>
              </div>

              <div @click="toggleCategory(category)" :class="{'bg-green-600':hasCategory(category)}" v-for="category in categories" :key="category.id" class="inline-block py-1 px-2 rounded-lg bg-gray-300 text-sm mr-2 mb-1 text-gray-50 hover:bg-sky-600 cursor-pointer">
                {{ category.name }}
              </div>

            </div>
            <base-button block class="mt-3">Opslaan</base-button>

          </form>

        </div>

        <div class="py-6" v-if="!isAuthenticated">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-3/4 text-center pb-2 mx-auto">Voeg toe aan je account</div>
          <p class="text-gray-400 text-sm text-center">Wil je de dagenteller bewaren? Meld je aan en bewaar hem in je account.</p>

          <form @submit="signInWithCredentials" class="my-6">
            <base-input required type="email" v-model="login.email" name="email" :error="error" autocomplete="email">
              E-mailadres
              <template #prefix><font-awesome-icon icon="at" /></template>
            </base-input>
            <base-input required v-model="login.password" class="mt-3" type="password" name="password" :error="error">
              Wachtwoord
              <template #prefix><font-awesome-icon :icon="['fas','lock']" /></template>
              <template #explain><router-link :to="{name: 'PasswordForgotten'}">Wachtwoord vergeten?</router-link></template>
            </base-input>
            <base-checkbox v-model="login.keep" class="mt-3">Onthoud mij</base-checkbox>
            <base-button block class="mt-6">Inloggen</base-button>
          </form>

        </div>

      </div>
    </div>

    <BaseNotification @close="showNotification = false" :show="showNotification">
      <template #title>
        Success opgeslagen!
      </template>
      <template>
        Deze dagenteller is terug te vinden via je persoonlijke dagentellers.
      </template>
    </BaseNotification>

  </div>
</template>

<script>

import toDate from "date-fns/toDate";
import differenceInSeconds from "date-fns/differenceInSeconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInWeeks from "date-fns/differenceInWeeks";
import differenceInYears from "date-fns/differenceInYears";
import intervalToDuration from "date-fns/intervalToDuration"
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isDate from "date-fns/isDate";
import isValid from "date-fns/isValid";
import add from "date-fns/add";
import sub from "date-fns/sub";
import format from "date-fns/format";

import dayjs from "dayjs";

import {formatDate} from "@/plugins";

export default {
  name: "Calculate",
  data() {
    return {
      showNotification: false,
      copied: false,
      types: [
          'seconds','minutes','hours','days','weeks','months','years'
      ],
      calculation: {},
      data: {
        amount: [{
          value: 0,
          type: 'days',
          subtract: false
        }],
      },
      error: null,
      loading: true,
      login: {
        email: null,
        password: null,
        keep: false
      },
      startInterval: null,
      endInterval: null,
      diff: {},
      diff_exact: false,
      precise: {},
      daycounter: {
        name: '',
        description: '',
        categories: [],
      },
      categories: [],
      firestoreId: this.$route.params.firestore_id || null,
    }
  },
  computed: {
    originUrl() {
      return window.location.origin;
    },
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
    isAnonymous() {
      return this.$store.getters['user/isAnonymous'];
    },
    isOfCurrentUser() {
      return this.$store.getters['user/getUser']?.id === this.data.userId && this.data.userId !== null;
    },
    userId() {
      return this.$store.getters['user/getUser']?.id;
    },
    isLive() {
      return this.calculation?.start?.isNow === true || this.calculation?.end?.isNow === true
    },
    noDiff() {
      return (this.calculation?.end?.isNow === true && this.calculation?.start?.isNow === true);
    },
    reversedTypes() {
      const types = [ ... this.types ];
      const reversed = types.reverse();
      return reversed;
    },
    calculateAmounts() {
      let amounts = [];
      let date = toDate(this.calculation?.start?.date);
      this.calculation?.amount.forEach(a => {
        let addSub = {};
        addSub[a.type] = a.value;
        date = (a.subtract === true) ? sub(toDate(date), addSub) : add(toDate(date), addSub);
        amounts.push({ date,  ...a});
      })
      return amounts;
    }
  },
  async created() {
    this.loading = true;
    this.categories = await this.$services['category'].getAll([{private:true}]);
    let s = this.$store.getters['daycounter/get'];

    if ((this.firestoreId !== null && this.firestoreId !== this.data.firestore_id) || (this.data.start === null && this.data.end === null)) {
       s = await this.$services['dayCounter'].get(this.firestoreId);
    }
    this.daycounter = { name: s?.name, description: s?.description, categories: s?.categories ?? [] }

    this.loading = false;
    this.calculation = JSON.parse(JSON.stringify(s));
    this.calculation.start.date = formatDate(s.start.date);
    this.calculation.end.date = formatDate(s.end.date);
    this.calculation.amount = JSON.parse(JSON.stringify(s.amount));

    this.data = JSON.parse(JSON.stringify(s));
    this.data.start.date = formatDate(s.start.date);
    this.data.end.date = formatDate(s.end.date);
    this.data.amount = JSON.parse(JSON.stringify(s.amount));

    this.processDates();
  },

  beforeDestroy() {
    this.data = null;

    clearInterval(this.startInterval);
    clearInterval(this.endInterval);

  },
  methods: {
    isValidDate(x) {
      return isDate(x);
    },
    copyShare() {
      this.$refs.share.focus();
      document.execCommand('copy');
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 5000)
    },
    async saveDaycounter(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const data = { ...this.calculation, ...this.daycounter};
      data.userId = this.$store.getters['user/getUser'].id;
      await this.$services['dayCounter'].update(data.firestore_id, data);
      this.showNotification = true;
      setTimeout(() => {
        this.showNotification = false;
      }, 7500)
    },
    hasCategory(cat) {
      return this.daycounter.categories.find(c => c.id === cat.id);
    },
    clearCategories() {
      this.daycounter.categories = [];
    },
    toggleCategory(cat) {
      const category = this.daycounter.categories.findIndex(c => c.id === cat.id);
      if (category !== -1) {
        this.daycounter.categories.splice(category,1);
      } else {
        this.daycounter.categories.push({...cat});
      }
    },
    zeroTime(time) {
      time = toDate(time);
      if (isDate(time) && isValid(time)) {
        return parseInt(format(time, 'HHmmss')) === 0;
      }
      return true;
    },
    async calculate(event, data) {
      event.stopImmediatePropagation();
      event.preventDefault();

      this.data = data;

      await this.$store.dispatch('daycounter/update', this.data);
      await this.$store.dispatch('daycounter/store');
      this.calculation = { ...this.$store.getters['daycounter/get']};
    },
    processDates() {
      clearInterval(this.startInterval);
      if (this.calculation?.start?.isNow === true) {
        this.startInterval = setInterval(() => {
          this.calculation.start.date = new Date();
        }, 100);
      }

      clearInterval(this.endInterval);
      if (this.calculation?.end?.isNow === true && this.calculation?.type === false) {
        this.endInterval = setInterval(() => {
          this.calculation.end.date = new Date();
        }, 100);
      }

      let start = formatDate(this.calculation?.start?.date);
      let end = formatDate(this.calculation?.end?.date);

      if (this.calculation?.end?.isNow) {
        if (isBefore(start,end)) {
          end = toDate(start);
          start = formatDate(this.calculation?.end?.date);
        } else if (isAfter(start,end)) {
          end = toDate(start);
          start = formatDate(this.calculation?.end?.date);
        }
      }

      this.precise = intervalToDuration({ end, start });
      if (this.diff_exact === false) {
        this.diff.seconds = this.noDiff ? 0 : differenceInSeconds(end, start);
        this.diff.minutes = this.noDiff ? 0 : differenceInMinutes(end, start);
        this.diff.hours = this.noDiff ? 0 : differenceInHours(end, start);
        this.diff.days = this.noDiff ? 0 : differenceInDays(end, start);
        this.diff.weeks = this.noDiff ? 0 : differenceInWeeks(end, start);
        this.diff.months = this.noDiff ? 0 : differenceInMonths(end, start);
        this.diff.years = this.noDiff ? 0 : differenceInYears(end, start);
      } else {
        let s = dayjs(toDate(start));
        let e = dayjs(toDate(end));
        this.diff.seconds = this.noDiff ? 0 : e.diff(s, 'second', this.diff_exact);
        this.diff.minutes = this.noDiff ? 0 : e.diff(s, 'minute', this.diff_exact);
        this.diff.hours = this.noDiff ? 0 : e.diff(s, 'hour', this.diff_exact);
        this.diff.days = this.noDiff ? 0 : e.diff(s, 'day', this.diff_exact);
        this.diff.weeks = this.noDiff ? 0 : e.diff(s, 'week', this.diff_exact);
        this.diff.months = this.noDiff ? 0 : e.diff(s, 'month', this.diff_exact);
        this.diff.years = this.noDiff ? 0 : e.diff(s, 'year', this.diff_exact);
      }


    },
    getSpacer(total) {
      let tmp = this.precise;
      delete tmp.firstDateWasLater;

      let array = [];
      for(const [key, value] of Object.entries(tmp)) {
        if (value) {
          array.push(key);
        }
      }

      if (array.indexOf(total) !== array.length - 1) {
        return ',';
      }

      return '';

    },
    signInWithCredentials(event) {
      this.error = null;
      event.preventDefault();
      event.stopImmediatePropagation();

      if (!this.validateEmail(this.login.email)) {
        this.error = this.error || {};
        this.error.email = true;
      }
      if (!this.validatePassword(this.login.password)) {
        this.error = this.error || {};
        this.error.password = true;
      }

      if (this.error === null) {
        localStorage.removeItem("keepEmail");
        if (this.login.keep) {
          localStorage.setItem("keepEmail", this.login.email);
        }

        this.$store.dispatch("user/login", this.login).then(() => {
          this.$router.push({
            name: 'Account_Daycounter',
          });
        }).catch(() => {
          this.error = this.$store.getters["user/getError"];
        });
      }
    },
  },
  watch: {
    calculation: {
      deep: true,
      handler() {
        this.processDates();
      }
    },
    diff_exact() {
      this.processDates()
    }
  }
}
</script>

<style scoped lang="scss">
.calculation {
  @apply order-last;
  @media (max-width: theme("screens.lg")) {
    @apply order-first;
  }
}
</style>