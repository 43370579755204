<template>
  <div v-bind="$attrs">
    <template v-if="!empty"><slot></slot><slot name="post"></slot></template>
    <div v-if="empty" class="skeleton"></div>
  </div>
</template>

<script>
export default {
  name: "Base",
  computed: {
    empty() {
      return (this.$slots.default[0].text === '')
    }
  }
}
</script>

<style scoped lang="scss">
.skeleton {
  @apply w-auto rounded bg-gray-300 h-2 animate-pulse bg-gradient-to-r from-gray-100 to-gray-200;
}
</style>