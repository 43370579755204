import Vue from "vue";

import toDate from "date-fns/toDate";
import differenceInYears from "date-fns/differenceInYears";

Vue.filter('momentDiff', function (v, format, end) {
    if (!v) return '';
    end = toDate(end);
    let m = toDate(v);

    if (format === 'Y') {
        return differenceInYears(end, m);
    }
    return '';
});