<template>
  <div>
    <Navigation />

    <div class="page">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      result: [],
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.page {
  @apply container mx-auto mt-6;
}
</style>