<template>
  <Item v-on="$listeners" class="item">
    <div class="bg-gray-400 m-2 rounded-xl text-center py-10 text-gray-300" v-if="!data.start && !data.end">
      Voeg via instellingen een datum toe
    </div>
    <div v-else>
      <daycounter-result :data="data" />
    </div>
  </Item>
</template>

<script>
import Item from "./Item";

export default {
  name: "ItemDaycounter",
  components: { Item },
  props: {
    data: {
      required: true,
      type: [ Object ],
    }
  }
}
</script>

<style scoped lang="scss">

</style>