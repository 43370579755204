import Vue from "vue";
import createService from "@/services/Service";

const services = {
    install(Vue) {

        const services = {
            "category":         createService("category"),
            "datecategory":     createService("datecategory"),
            "dayCounter":       createService("dayCounters"),
            "privateDyCounter": createService("privateDayCounters"),
            "globalDayCounter": createService("globalDayCounters"),
            "nationalHolidays": createService("nationalHolidays"),
            "user":             createService("users")
        }

        Vue.prototype.$services = services;
    }
}
Vue.use(services);

/**
 * TODO: aanmaken gebruiker slack message
 * TODO: aanmaken daycounter slack message
 *
 */