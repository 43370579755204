<template>
  <div>
    <div class="flex">
      <div>
        <router-link :to="{name: 'Admin_Category'}">Category</router-link>
      </div>
      <div>
        <router-link :to="{name: 'Admin_Category_Create'}">Toevoegen</router-link>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "DayCounter"
}
</script>

<style scoped>

</style>