<template>
  <div>
    <select class="py-1 select" v-model="selected" @change="select">
      <option v-for="option in options()" :value="option.value" :key="option.value">{{ option.text }}</option>
    </select>
  </div>
</template>

<script>
import format from "date-fns/format";
import setMonth from "date-fns/setMonth";
import nl from "date-fns/locale/nl";

export default {
  name: "SelectMonth",
  data() {
    return {
      selected: this.value
    };
  },
  props: ["value"],
  methods: {
    select() {
      this.$emit("input", this.selected);
      this.$emit("change", this.selected);
    },
    options() {
      let options = [];
      for (let i = 0; i < 12; i++) {
        options.push({
          value: i + 1,
          text: format(setMonth(new Date(), i),"MMMM", { locale: nl })
        });
      }
      return options;
    }
  },
  watch: {
    value(to) {
      this.selected = to;
    }
  }
};
</script>
