<template>
  <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div class="flex-1 bg-white p-6 flex flex-col justify-between cursor-pointer" @click="goto({name:'Daycounter',params: {firestore_id: daycounter.id}})">
      <div class="flex-1 flex">
        <div class="block flex-grow">
          <p class="text-xl font-semibold text-gray-900" v-if="daycounter.name">{{ daycounter.name }}</p>
          <p class="text-xl text-gray-300" v-else>Geen titel</p>
          <p class="text-xs text-slate-300">{{ daycounter.id }}</p>
          <p class="mt-3 text-base text-gray-500" v-if="daycounter.description">{{ daycounter.description }}</p>
          <p class="mt-3 text-base text-gray-200" v-else>Voeg een omschrijving toe</p>
        </div>
        <div class="flex-shrink-0">
          <font-awesome-icon v-if="!daycounter.favorite" :icon="['far','star']" @click.stop="setFavorite(true)" />
          <font-awesome-icon v-if="daycounter.favorite" :icon="['fas','star']" class="text-yellow-400" @click.stop="setFavorite(false)" />
        </div>
      </div>

      <div class="mt-6" v-if="daycounter.categories">
        <div v-for="category in daycounter.categories" :key="category.id" class="bg-green-600 inline-block py-1 px-2 rounded-lg bg-gray-300 text-sm mr-2 mb-1 text-gray-50 cursor-pointer">
          {{ category.name }}
        </div>
      </div>

      <div class="mt-6 flex items-center">
        <div v-if="!daycounter.type">
          <p class="text-sm font-medium text-gray-900">Datum berekening</p>
          <div class="flex space-x-1 text-center text-gray-500 items-center">
            <time> {{ start | format('PPPP pp') }} </time>
            <font-awesome-icon icon="sort" class="text-orange-400" rotation="90" v-if="!isLive || isEqual" />
            <font-awesome-icon icon="caret-down" class="text-green-600" rotation="90" v-if="!isEqual && isLive && diff.seconds < 0" />
            <font-awesome-icon icon="caret-up" class="text-red-500" rotation="90" v-if="!isEqual && isLive && diff.seconds >= 0" />
            <time> {{ end | format('PPPP pp') }} </time>
          </div>
        </div>
        <div v-if="daycounter.type">
          <p class="text-sm font-medium text-gray-900">Aantallen berekening</p>
          <div class="flex space-x-1 text-center text-gray-500 items-center">
            <time> {{ start | format('PPPP pp') }} </time>
          </div>
          <div>
            <span v-for="(amount, i) in daycounter.amount" :key="i" :class="{'text-red-500':amount.subtract,'text-green-500':!amount.subtract}">
              {{ amount.value | number }} {{ amount.type | timeLabel(amount.value) }}<span v-if="i < (daycounter.amount.length - 1)">, </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 px-4 text-xs text-center flex cursor-pointer" @click="goto({name:'Daycounter',params: {firestore_id: daycounter.id}})">
      <div class="flex-grow" v-if="!isEqual && !daycounter.type"><daycounter-precise :start="start" :end="end" /></div>
      <div class="flex-grow" v-if="daycounter.type">{{ calculateAmountDate(start) | format('PPPP pp')}}</div>
      <div class="flex-shrink-0 text-gray-300 hover:text-red-500" @click.stop="modalDelete = true">
        <font-awesome-icon icon="trash" />
      </div>
    </div>

    <BaseModal @click.stop @cancel="modalDelete = false" @submit="deleteDaycounter" variant="warning" v-if="modalDelete">
      <template #title>Verwijderen dagenteller</template>
      <template>Weet je zeker dat je deze dagenteller wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.</template>
      <template #button-ok>Verwijderen</template>
    </BaseModal>

    <BaseNotification @click.stop @close="showNotification = false" :show="showNotification">
      <template #title>
        Dagenteller verwijderd!
      </template>
      <template>
        De dagenteller is succesvol verwijderd uit je persoonlijke dagentellers.
      </template>
    </BaseNotification>
  </div>
</template>

<script>
import toDate from "date-fns/toDate";
import differenceInSeconds from "date-fns/differenceInSeconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInWeeks from "date-fns/differenceInWeeks";
import differenceInYears from "date-fns/differenceInYears";
import intervalToDuration from "date-fns/intervalToDuration"
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import sub from "date-fns/sub";
import add from "date-fns/add";

import {formatDate} from "@/plugins";

export default {
  name: "card",
  props: {
    daycounter: {
      required: true
    }
  },
  data() {
    return {
      modalDelete: false,
      showNotification: false,
      startInterval: null,
      endInterval: null,
      start: null,
      end: null,
      isLive: false,
      diff: {},
      precise: {},
    }
  },
  computed: {
    isEqual() {
      return this.daycounter.start?.isNow && this.daycounter.end?.isNow;
    },
  },
  methods: {
    async deleteDaycounter() {
      this.modalDelete = false;
      this.daycounter.userId = null;
      await this.$services['dayCounter'].update(this.daycounter.id, this.daycounter);
      this.showNotification = true;

      this.$emit('reload');
    },
    calculateAmountDate(start) {
      let amounts = [];
      let date = toDate(start);
      this.daycounter?.amount.forEach(a => {
        let addSub = {};
        addSub[a.type] = a.value;
        date = (a.subtract === true) ? sub(toDate(date), addSub) : add(toDate(date), addSub);
        amounts.push({ date,  ...a});
      })
      return amounts[amounts.length - 1].date;
    },
    setFavorite(fav) {
      this.daycounter.favorite = fav;
      this.$services['dayCounter'].update(this.daycounter.id, this.daycounter);
    },
    goto(route) {
      this.$router.push(route);
    },
    date(date) {
      if (date.isValid()) {
        return date.format('dddd DD MMMM YYYY HH:mm:ss');
      }
      return '';
    },
    process() {
      let start = toDate(this.start);
      let end = toDate(this.end);

      if (isBefore(start,end)) {
        end = toDate(start);
        start = toDate(this.end);
      } else if (isAfter(start,end)) {
        end = toDate(start);
        start = toDate(this.end);
      }

      if (isValid(start) && isValid(end)) {
        this.precise = intervalToDuration({end, start});
      }

      this.diff.seconds = differenceInSeconds(end, start);
      this.diff.minutes = differenceInMinutes(end, start);
      this.diff.hours = differenceInHours(end, start);
      this.diff.days = differenceInDays(end, start);
      this.diff.weeks = differenceInWeeks(end, start);
      this.diff.months = differenceInMonths(end, start);
      this.diff.years = differenceInYears(end, start);


    }
  },
  destroyed() {
    clearInterval(this.startInterval);
    clearInterval(this.endInterval);
  },
  watch: {
    daycounter: {
      immediate: true,
      deep: true,
      handler(to) {
        if (to?.start?.isNow === true) {
          this.isLive = true;
          this.start = new Date();
          this.startInterval = setInterval(() => {
            this.start = new Date();
            this.process();
          }, 500);
        } else {
          this.start = formatDate(to?.start?.date);
        }
        if (to?.end?.isNow === true) {
          this.isLive = true;
          this.end = new Date();
          this.endInterval = setInterval(() => {
            this.end = new Date();
            this.process();
          }, 500);
        } else {
          this.end = formatDate(to?.end?.date);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>