import { render, staticRenderFns } from "./TextDaycounter.vue?vue&type=template&id=4beb2452&scoped=true&"
import script from "./TextDaycounter.vue?vue&type=script&lang=js&"
export * from "./TextDaycounter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4beb2452",
  null
  
)

export default component.exports