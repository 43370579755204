import Vue from "vue";
import {formatDate} from "@/plugins";
//import {parseISO, isDate, isValid, toDate} from "date-fns";

export default {
    namespaced: true,
    state: {
        start: null,
        end: null,
        type: null,
        amount: null,
        amount_type: null,
        amount_plus: null,
        firestore_id: null,
    },
    getters: {
        get: (state) => {
            let data = JSON.parse(JSON.stringify(state));
            if (data?.start?.date !== undefined) {
                data.start.date = formatDate(data.start.date);
            }

            if (data?.end?.date !== undefined) {
                data.end.date = formatDate(data.end.date);
            }

            return data;
        }
    },
    mutations: {
        set: (state, data) => {
            data = { ...data };
            state.start = data.start;
            state.end = data.end;
            state.type = data.type;
            state.amount = data.amount ?? null;
            state.amount_type = data.amount_type ?? null;
            state.amount_plus = data.amount_plus ?? null;
            state.name = data?.name ?? null;
            state.description = data?.description ?? null;
        },
        id: (state, payload) => {
            state.firestore_id = payload || null;
        }
    },
    actions: {
        create: ({ commit }, payload) => {
            commit('set', payload);
            commit('id', null);
        },
        update: ({ commit, state }, payload) => {
            commit('set', payload);
            console.log(payload, state, 'update')
            if (payload?.firestore_id && state.firestore_id !== payload.firestore_id) {
                commit('id', payload.firestore_id);
            }
        },
        store: async ({ rootGetters, commit, getters, dispatch}) => {
            let data = { ...getters['get'] };

            if (!rootGetters['user/isAuthenticated']) {
                console.log('niet ingelogd..')
                await dispatch('user/anonymous1',null,{ root: true});
            }

            console.log(rootGetters['user/getUser'], 'getUser');

            data.userId = rootGetters['user/getUser'].uid;

            console.log('store data', data);

            if (data.firestore_id !== null) {
                await Vue.prototype.$services['dayCounter'].update(data.firestore_id, data);
            } else {
                let response = await Vue.prototype.$services['dayCounter'].insert(data);
                commit('id', response.id);

                let slack = Vue.prototype.$slack;
                let x = slack.addText(`Nieuwe dagen teller aangemaakt #${response.id}`);
                x.addButton('Bekijk dagenteller', 'http://localhost:8080/dagenteller/' + response.id);
                x.action('user');
                x.send();
            }
            return getters['get'];
        },
        get: async ({dispatch, commit, getters}, payload) => {
            let data = await Vue.prototype.$services['dayCounter'].get(payload);
            dispatch('create', data);
            commit('id', payload);
            return getters['get'];
        }
    }
};
