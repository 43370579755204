<template>
  <div v-bind="$attrs" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Base"
}
</script>

<style scoped lang="scss">
div /* deep */ button {
  @apply rounded-none;
  border-right-color: rgba(255,255,255,0.25);

  &:first-child {
    @apply rounded-l;
  }
  &:last-child {
    @apply rounded-r;
  }
}
</style>