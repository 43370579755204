<template>
  <div class="my-20">

    <base-alert variant="error" v-if="error" class="mb-10">{{ error.message }}</base-alert>

    <div class="grid grid-cols-3 divide-x gap-0 mt-10">
      <div class="col-span-3">
        <div class="w-4/5 m-auto divide-y">
          <div>
            <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-4/5 text-center pb-2 mx-auto">Account gegevens</div>
            <p class="text-gray-400 text-sm text-center">{{ user.type }}</p>
            <dl>
              <dt class="mt-6 text-sm font-medium text-gray-500">
                Naam
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.displayName }}
              </dd>

              <dt class="mt-6 text-sm font-medium text-gray-500">
                E-mailadres
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ user.email }}
              </dd>

              <dt class="mt-6 text-sm font-medium text-gray-500">
                Geboortedatum
              </dt>
              <dd class="mt-1 text-sm text-gray-900 flex">
                <span class="flex-grow">{{ user.dateOfBirth | format('PPPP') }}</span>

              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSettings",
  data() {
    return {
      error: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    }
  }
}
</script>

<style scoped>

</style>