<template>
  <div class="border border-gray-100 rounded-md p-2">
    <div class="flex">
      <div class="flex-1 pr-1.5">
        <div class="bg-gray-200 w-full h-full rounded-md"></div>
      </div>
      <div class="flex-1">
        <div class="bg-gray-200 w-1/5 h-2 mr-1.5 inline-block rounded-sm"></div>
        <div class="bg-gray-200 w-1/5 h-2 mr-1.5 inline-block rounded-sm"></div>
        <div class="bg-gray-200 w-2/5 h-2 mr-1.5 inline-block rounded-sm"></div>
      </div>
    </div>
    <p>Tekst / dagenteller element</p>
  </div>
</template>

<script>
export default {
  name: "ItemTextDaycounter"
}
</script>

<style scoped>

</style>