<template>
  <Item v-on="$listeners">
    <div>
      <div class="bg-gray-400 m-2 rounded-xl text-center py-10 text-gray-300" v-if="!data.url">
        Voeg url toe van afbeelding via instellingen
      </div>
      <img :src="data.url" v-if="data.url" class="w-full h-auto" />
    </div>
  </Item>
</template>

<script>
import Item from "./Item";
export default {
  name: "ItemImage",
  components: {Item},
  props: {
    data: {
      required: true,
      type: [ Object ]
    }
  }
}
</script>

<style scoped>

</style>