<template>
  <div v-on="$listeners">

  </div>
</template>

<script>
export default {
  name: "SettingsText",
  props: {
    data: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>