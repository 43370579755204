import Vue from "vue";
import Navigation from "@/components/elements/navigation/Navigation";

import DaycounterResult from "@/components/elements/daycounter/Result";
import DaycounterPrecise from "@/components/elements/daycounter/Precise";

import BaseTextarea from "@/components/form/textarea/Base";
import BaseInput from "@/components/form/input/Base";
import BaseButton from "@/components/form/button/Base";
import BaseGroupButton from "@/components/form/button/group/Base";

import BaseSelect from "@/components/form/select/Base";

import BaseCheckbox from "@/components/form/checkbox/Base";

import BaseToggle from "@/components/form/toggle/Base";

import FormCalculator from "@/components/form/Calculator";

import BaseAlert from "@/components/alert/Base";

import BaseSkeleton from "@/components/skeleton/Base";

import BaseModal from "@/components/modal/Base";
import BaseNotification from "@/components/notification/Base";

Vue.component('Navigation', Navigation);

Vue.component('DaycounterResult', DaycounterResult);
Vue.component('DaycounterPrecise', DaycounterPrecise);

Vue.component('BaseTextarea', BaseTextarea);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseButtonGroup', BaseGroupButton);

Vue.component('BaseSelect', BaseSelect);

Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseToggle', BaseToggle);

Vue.component('FormCalculator', FormCalculator);

Vue.component('BaseAlert', BaseAlert);

Vue.component('skeleton',BaseSkeleton);

Vue.component('BaseModal', BaseModal);
Vue.component('BaseNotification', BaseNotification);