<template>
  <div class="border border-gray-100 rounded-md p-2">
    <div class="bg-gray-200 w-full h-10 rounded-md"></div>
    <p>Afbeelding element</p>
  </div>
</template>

<script>
export default {
  name: "ItemImage"
}
</script>

<style scoped>

</style>