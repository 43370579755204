<template>
  <div class="border border-gray-100 rounded-md p-2">
    <div class="bg-gray-200 w-full h-2 mb-1.5 rounded-sm"></div>
    <div class="bg-gray-200 w-full h-2 mb-1.5 rounded-sm"></div>
    <div class="bg-gray-200 w-3/4 h-2 mb-1.5 rounded-sm"></div>
    <p>Tekst element</p>
  </div>
</template>

<script>
export default {
  name: "ItemText"
}
</script>

<style scoped>

</style>