import Vue from "vue";

Vue.filter('timeLabel', function (v, count) {
    if (!v || count === undefined || count === null) return '';
    count = parseInt(count);
    switch(v) {
        case 's':
        case 'second':
        case 'seconds':
            return (count === 1) ? 'seconde' : 'seconden';
        case 'm':
        case 'minute':
        case 'minutes':
            return (count === 1) ? 'minuut' : 'minuten';
        case 'h':
        case 'hour':
        case 'hours':
            return (count === 1) ? 'uur' : 'uren';
        case 'd':
        case 'day':
        case 'days':
            return (count === 1) ? 'dag' : 'dagen';
        case 'w':
        case 'week':
        case 'weeks':
            return (count === 1) ? 'week' : 'weken';
        case 'M':
        case 'month':
        case 'months':
            return (count === 1) ? 'maand' : 'maanden';
        case 'y':
        case 'year':
        case 'years':
            return (count === 1) ? 'jaar' : 'jaren';

        default:
            return '';
    }

});