<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input :id="name" type="checkbox" :name="name" value="1" v-model="v" :required="isRequired" :class="{'prefix':$slots.prefix}" v-bind="$attrs" />
    </div>
    <div class="ml-3 text-sm">
      <label :for="name" class="font-medium text-gray-700" v-if="$slots.default">
        <slot />
      </label>
      <p class="text-gray-500" v-if="$slots.sub"><slot name="sub" /></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    value: {
      required: false,
      type: [ String, Boolean, Number ],
      default: false
    },
    required: {
      required: false,
      default: false,
      type: [ String, Boolean ]
    },
  },
  computed: {
    isRequired() {
      return this.required === '' || this.required === true || this.required === 'required' || this.required === 'true';
    },
    name() {
      return this._uid;
    }
  },
  data() {
    return {
      v: null
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input',event.target.value);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.v = to;
      }
    },
    v: {
      immediate: true,
      handler(to) {
        this.$emit('input', to);
      }
    }
  }
}
</script>

<style scoped lang="scss">
input {
  @apply h-4 w-4 text-indigo-600 border border-gray-300 rounded outline-none ring-0;

  &:focus {
    @apply ring-0 outline-none ring-indigo-500;
  }
}
</style>