<template>
  <div class="calendar">

    <div class="mt-3 text-center">
      <div class="flex items-center text-gray-900">
        <button @click.prevent.stop="previousYear" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span class="sr-only">Vorig jaar</span>
          <font-awesome-icon :icon="['fas','chevrons-left']" />
        </button>
        <button @click.prevent.stop="previousMonth" type="button" class="-m-1.5 ml-3 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span class="sr-only">Vorige maand</span>
          <font-awesome-icon :icon="['fas','chevron-left']" />
        </button>
        <div class="flex-auto font-semibold">
          <select-month
              v-model="month"
              @change="setMonth"
              class="inline-block"
          ></select-month>
          <input
              class="input has-text-right year py-1 w-24 text-center"
              type="text"
              v-model="year"
          />
        </div>
        <button @click.prevent.stop="nextMonth" type="button" class="-m-1.5 mr-3 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span class="sr-only">Volgende maand</span>
          <font-awesome-icon :icon="['fas','chevron-right']" />
        </button>
        <button @click.prevent.stop="nextYear" type="button" class="-m-1.5  flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span class="sr-only">Volgend jaar</span>
          <font-awesome-icon :icon="['fas','chevrons-right']" />
        </button>
      </div>
      <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>D</div>
        <div>W</div>
        <div>D</div>
        <div>V</div>
        <div>Z</div>
        <div>Z</div>
      </div>
      <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        <template v-for="(week, i) in weeks">
          <template v-for="(day, ii) in week.days">
            <button :key="day | format('T')" @click="setDate(day)" type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10"
                    :class="getDayClasses(day, i, ii)">
              <time :datetime="day | format('yyyy-mm-dd')" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                    :class="{'bg-orange-500 font-semibold text-white':isToday(day), 'bg-sky-700 font-semibold text-white': isSelected(day)}"
              >{{  day | format("d") }}</time>
            </button>
          </template>
        </template>

      </div>
    </div>

    <div class="flex justify-between mt-6">
      <base-button size="xs" @click="setDay(-7)">Vorige week</base-button>
      <base-button size="xs" @click="setDay(-1)">Gisteren</base-button>
      <base-button size="xs" @click="setDay(0)">Vandaag</base-button>
      <base-button size="xs" @click="setNow">Nu</base-button>
      <base-button size="xs" @click="setDay(1)">Morgen</base-button>
      <base-button size="xs" @click="setDay(7)">Volgende week</base-button>
    </div>

    <div>
        <div class="field is-grouped is-grouped-multiline">
          <div class="control" v-for="item in nationalHolidays" :key="item.id">
            <div class="tags has-addons cursor-pointer" @click="setDate(item.date)">
              <span class="tag">{{ item.date | format('dd-mm')}}</span>
              <span class="tag is-info">{{ item.name }}</span>
            </div>
          </div>
        </div>

    </div>
  </div>

</template>

<script>
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import isSameMonth from "date-fns/isSameMonth";
import toDate from "date-fns/toDate";
import add from "date-fns/add";
import sub from "date-fns/sub";
import getDay from "date-fns/getDay";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import setDate from "date-fns/setDate";
import startOfDay from "date-fns/startOfDay";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import startOfWeek from "date-fns/startOfWeek";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import lastDayOfWeek from "date-fns/lastDayOfWeek";

export default {
  name: "DagenTellerKalendar",
  props: ["date", "isNow"],
  data() {
    return {
      useDate: this.date,
      firstDayOfTheMonth: null,
      firstDayOfTheCalendar: null,
      lastDayOfTheMonth: null,
      lastDayOfTheCalendar: null,
      workingDay: null,
      weeks: [],
      days: [],
      month: 1,
      year: null,
      hour: null,
      minute: null,
      timeType: "none",
      nationalHolidays: []
    };
  },
  methods: {
    getDayClasses(day, i ,ii) {
      return {
        'rounded-tl-lg': i === 0 && ii === 0,
        'rounded-tr-lg': i === 0 && ii === 6,
        'rounded-bl-lg': i === this.weeks.length - 1 && ii === 0,
        'rounded-br-lg': i === this.weeks.length - 1 && ii === 6,
        'bg-gray-50 text-gray-400': !this.thisMonth(day),
        'text-gray-900': this.thisMonth(day)
      };
    },
    isToday(day) {
      return isSameDay(day, new Date());
    },
    isSelected(day) {
      return isSameDay(day, this.date);
    },
    thisMonth(day) {
      return isSameMonth(day, this.firstDayOfTheMonth);
    },
    calculateDays() {
      this.days = [];
      this.weeks = [];
      let daysInWeek = [];

      this.workingDay = toDate(this.firstDayOfTheCalendar);

      while (!isAfter(this.workingDay, this.lastDayOfTheCalendar)) {
        this.days.push(toDate(this.workingDay));
        daysInWeek.push(toDate(this.workingDay));

        this.workingDay = add(this.workingDay, { days: 1 });

        if (getDay(this.workingDay) === 1) {
          this.weeks.push({ days: daysInWeek });
          daysInWeek = [];
        }
      }
    },
    previousMonth() {
      this.setDate(sub(toDate(this.firstDayOfTheMonth), {months: 1}), undefined, false, false);
      this.updateMonthYear();
    },
    nextMonth() {
      this.setDate(add(toDate(this.firstDayOfTheMonth), { months: 1}), undefined, false, false);
      this.updateMonthYear();
    },
    previousYear() {
      this.setDate(sub(toDate(this.firstDayOfTheMonth), { years: 1}), undefined, false, false);
      this.updateMonthYear();
    },
    nextYear() {
      this.setDate(add(toDate(this.firstDayOfTheMonth), { years: 1}), undefined, false, false);
      this.updateMonthYear();
    },
    setDate(day, format, isNow = false, close = true) {
      if (isNow !== true) {

        if (
          this.hour !== null &&
          this.minute !== null &&
          this.hour !== undefined &&
          this.minute !== undefined
        ) {
          setMinutes(setHours(day, this.hour), this.minute);
          //day.hour(this.hour).minute(this.minute);
          format = "Pp";
        }
      }
      this.$emit("setDate", {
        date: day,
        format: format || "P",
        isNow: isNow || false,
        close
      });
    },
    setDay(a) {
      const day = startOfDay(new Date());
      setDate(add(day, { days: a }));
    },
    setMonth() {
      this.useDate = startOfMonth(new Date(this.year, this.month - 1, 0));
    },
    setNow() {
      this.setDate(new Date(), "Pp", true);
    },
    setTime(timeType, hour, minute) {
      this.timeType = timeType;
      this.hour = hour;
      this.minute = minute;
    },
    setCustomTime() {
      this.timeType = "custom";

      if (this.hour === null && this.minute === null) {
        this.timeType = "none";
      } else if (this.hour === null) {
        this.hour = 0;
      } else if (this.minute === null) {
        this.minute = 0;
      }
    },
    isTimeType(type) {
      return this.timeType === type ? "primary" : "secondary";
    },
    getHours() {
      let hours = [{ value: null, text: "" }];
      let i = 0;
      while (i < 24) {
        hours.push({ value: i, text: i });
        i++;
      }
      return hours;
    },
    getMinutes() {
      let minutes = [{ value: null, text: "" }];
      let i = 0;
      while (i < 60) {
        minutes.push({ value: i, text: i < 10 ? "0" + i : i });
        i++;
      }
      return minutes;
    },
    updateMonthYear() {
      this.year = getYear(this.useDate)
      this.month = getMonth(this.useDate) + 1;
    },
    updateHourMinute() {
      const date = toDate(this.useDate);
      if (this.isNow) {
        this.timeType = "none";
        this.hour = null;
        this.minute = null;
      } else if (getHours(date) !== 0 || getMinutes(date) !== 0) {
        this.timeType = "custom";
        this.hour = getHours(date);
        this.minute = getMinutes(date);
      }
    }
  },
  mounted() {
    this.useDate = this.date || null;
  },
  created() {
    this.useDate = this.date;
    this.updateMonthYear();
    this.updateHourMinute();
  },
  watch: {
    date(to) {
      this.useDate = to || null;
      if (to) {
        this.updateMonthYear();
        this.updateHourMinute();
      }
    },
    useDate(to) {
      if (to) {

        this.firstDayOfTheMonth = startOfMonth(toDate(to));
        this.firstDayOfTheCalendar = startOfWeek(this.firstDayOfTheMonth, { weekStartsOn: 1 });
        this.lastDayOfTheMonth = lastDayOfMonth(toDate(to));
        if (getDay(this.lastDayOfTheMonth) !== 6) {
          this.lastDayOfTheCalendar = lastDayOfWeek(this.lastDayOfTheMonth, { weekStartsOn: 1 });
        } else {
          this.lastDayOfTheCalendar = toDate(this.lastDayOfTheMonth);
        }
        this.calculateDays();
      }
    },
    year(to) {
      this.useDate = startOfMonth(new Date(to, this.month - 1, 1));
    }
  }
};
</script>

<style lang="scss" scoped>
//$color-grey: #797e98;
//$color-blue: #5f92ff;
//$color-orange: #fe795a;
//$color-lightblue: #3fc6fe;
//$color-background: #f8f9fa;
//
//.calendar {
//  position: absolute;
//  // top: 2.5em;
//  left: 0;
//  z-index: 100;
//  width: 100%;
//  background-color: $color-background;
//  padding: 0.75em;
//
//  input {
//    &.year {
//      width: 6em;
//    }
//  }
//  /**input {
//    border-left: none;
//    border-top: none;
//    border-right: none;
//    border-radius: 0;
//    background: none;
//
//    &.year {
//      margin-left: 0.5rem;
//      width: 6rem;
//      font-size: 1.5em;
//      flex: none;
//    }
//  }
///**  .selectWrapper {
//    position: relative;
//    &:before {
//      content: "›";
//      color: $color-grey;
//      font-size: 1.25em;
//      transform: rotate(90deg);
//      position: absolute;
//      top: 0.5em;
//      right: 0.25rem;
//    }
//    /deep/ select {
//      font-size: 1.5em;
//    }
//  }
//  /deep/ select {
//    border-left: none;
//    border-top: none;
//    border-right: none;
//    border-radius: 0;
//    background: none;
//    position: relative;
//    padding-right: 0.75em;
//  } **/
//  .label {
//    color: $color-grey;
//
//    div {
//      padding: 0;
//      span {
//        display: inline-block;
//        width: 2em;
//      }
//    }
//  }
  .day {
    @apply bg-gray-100 cursor-pointer h-9 leading-9 text-black;
    &.notInMonth {
      @apply bg-opacity-20 text-opacity-50;
    }
    &:hover {
      span {
        @apply bg-sky-400 #{!important};
      }
    }
    span {
      @apply rounded-full leading-8 w-8 h-8 inline-block;
    }
    &.today,
    &.selectedDay {
      color: white;
    }
    &.today {
      span {
        @apply bg-orange-500;
      }
    }
    &.selectedDay {
      span {
        @apply bg-sky-700;
      }
    }
  }
//  .a-button {
//    color: $color-grey;
//    background: none;
//    border: none;
//    font-size: 1.25em;
//    padding: 0.375rem;
//    cursor: pointer;
//  }
//}
//.badge {
//  cursor: pointer;
//}
</style>
