<template>
  <form @submit="submit">
    <div class="bg-white shadow-md filter-none rounded-2xl border border-gray-200 p-6">
      <slot name="header"></slot>
      <div class="my-4">
        <p class="mb-1 text-sm font-medium text-gray-700">Begindatum</p>
        <form-input-date :auto="!loading" v-model="data.start"></form-input-date>
      </div>

      <div class="flex">
        <div class="flex-grow text-lg my-auto text-right cursor-pointer" @click="data.type = false">Einddatum gebruiken</div>
        <div class="flex-shrink pt-1 mx-2">
          <base-toggle v-model="data.type" on="indigo-600" size="lg" off="gray-200">
            <template #icon-off>
              <font-awesome-icon class="text-gray-500" icon="calendar" />
            </template>
            <template #icon-on>
              <font-awesome-icon class="text-indigo-500" icon="calculator" />
            </template>
          </base-toggle>
        </div>
        <div class="flex-grow text-lg my-auto cursor-pointer" @click="data.type = true">Rekenen met aantallen</div>
      </div>

      <div class="my-4" v-show="data.type === false">
        <p class="mb-1 text-sm font-medium text-gray-700">Einddatum</p>
        <form-input-date :auto="!loading" v-model="data.end"></form-input-date>
      </div>
      <div class="my-4" v-show="data.type === true">
        <p class="mb-1 text-sm font-medium text-gray-700">Selecteer aantallen</p>
        <div class="grid grid-cols-8 md:grid-cols-6 gap-4" v-for="(amount, id) in data.amount" :key="id">
          <div class="col-span-3 md:col-span-3">
            <base-input extra_class="leading-8 p-4 border-gray-200 text-right" v-model="amount.value">
              <template #prefix v-if="data.amount.length > 1">
                <font-awesome-icon icon="trash" class="hover:text-red-600 cursor-pointer"  @click="removeAmount(id)" />
              </template>
            </base-input>
          </div>
          <div class="col-span-3 md:col-span-2">
            <base-select v-model="amount.type" extra_class="pt-3 pb-4" :data="amount_types"></base-select>
          </div>
          <div class="col-span-2 md:col-span-1 pt-3">
            <base-toggle v-model="amount.subtract" off="green-600" on="red-600" size="lg">
              <template #icon-off>
                <font-awesome-icon icon="plus" class="text-green-600" />
              </template>
              <template #icon-on>
                <font-awesome-icon icon="minus" class="text-red-600" />
              </template>
            </base-toggle>
          </div>
        </div>

        <div class="text-right text-sky-600 cursor-pointer mt-3">
          <base-button type="button" variant="info" size="sm" @click.stop="addAmount">Nog een aantal toevoegen + </base-button>
        </div>
      </div>
      <div>
        <base-button size="lg" block :disabled="!notSameDate">Herbereken je dagenteller</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import isSameSecond from "date-fns/isSameSecond";

export default {
  name: "Calculator",
  props: {
    value: {
      required: true
    },
    loading: {
      required: false,
      default: false,
    }
  },
  data() {
    return {
      data: null,
      amount_types: [
        {id:'seconds',value:'Seconden'},
        {id:'minutes',value:'Minuten'},
        {id:'hours',value:'Uren'},
        {id:'days',value:'Dagen'},
        {id:'weeks',value:'Weken'},
        {id:'months',value:'Maanden'},
        {id:'years',value:'Jaren'},
      ],
    }
  },
  computed: {
    notSameDate() {
      if (this.data.type === false) {
        if (this.data?.start?.isNow === true &&  this.data?.end?.isNow  === true) {
          return false;
        }
        if (isSameSecond(this.data?.start?.date,this.data?.end?.date)) {
          return false;
        }
        return true;
      } else {
        return this.data.amount?.[0]?.value !== 0;
      }
    }
  },
  methods: {
    submit(evt) {
      this.$emit('submit', evt, this.data);
    },
    addAmount() {
      this.data.amount.push({
        value: 0,
        type: 'days',
        subtract: false
      });
      return false;
    },
    removeAmount(id) {
      this.data.amount.splice(id, 1);
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(to) {
        this.data = to;
      }
    }
  }
}
</script>

<style scoped>

</style>