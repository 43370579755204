import axios from "axios";
import Vue from "vue";

class slack {
    constructor() {
        this.blocks = [];
        this.url = '';
    }

    reset() {
        this.blocks = [];
        this.url = '';
    }

    addText(text, icon) {
        let content = {
            "type": "context",
            "elements": [
                {
                    "type": "mrkdwn",
                    "text": text
                }
            ]
        };

        if (icon) {
            content.elements.unshift({
                "type": "image",
                "image_url": "https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png",
                "alt_text": "Google"
            });
        }

        this.blocks.push(content);
        return this;
    }

    addDivider() {
        this.blocks.push({
            'type': 'divider'
        })
        return this;
    }

    addButton(text, link) {
        this.blocks.push({
            "type": "actions",
            "elements": [
                {
                    "type": "button",
                    "text": {
                        "type": "plain_text",
                        "text": text,
                        "emoji": true
                    },
                    "value": "click_me_123",
                    "url": link
                }
            ]
        })
        return this;
    }
    action(type) {
        this.url = process.env['VUE_APP_SLACK_WEBHOOK_' + type.toUpperCase()];
        return this;
    }

    send() {
        axios.post(this.url, JSON.stringify({'blocks':this.blocks}),
            {
                withCredentials: false,
                transformRequest: [(data, headers) => {
                    delete headers.common.Authorization
                    delete headers.post['Content-Type']
                    return data
                }]
            }
        )
    }
}

Vue.use({
    install(Vue) {
        Vue.prototype.$slack = new slack();
    }
});
