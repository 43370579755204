<template>
  <div class="border border-gray-100 rounded-md p-2">
    <div class="flex">
      <div class="flex-1 pr-1.5">
        <div class="bg-gray-200 w-full h-full rounded-md"></div>
      </div>
      <div class="flex-1">
        <div class="bg-gray-200 w-full h-2 mb-1.5 rounded-sm"></div>
        <div class="bg-gray-200 w-full h-2 mb-1.5 rounded-sm"></div>
        <div class="bg-gray-200 w-3/4 h-2 mb-1.5 rounded-sm"></div>
      </div>
    </div>
    <p>Tekst / afbeelding element</p>
  </div>
</template>

<script>
export default {
  name: "ItemImageText"
}
</script>

<style scoped>

</style>