<template>
  <div>
    <base-alert v-if="error" variant="error">{{ error }}</base-alert>
    <form @submit="save">
      <div >
        <base-input v-model="data.name">Naam</base-input>
        <base-input v-model="data.key">Key</base-input>
        <base-checkbox v-model="data.private">Prive label</base-checkbox>
        <base-checkbox v-model="data.global">Globaal beschikbaar</base-checkbox>

        <base-button variant="info">Opslaan</base-button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "Add",
  data() {
    return {
      id: this.$route.params.id || null,
      error: null,
      data: {
        name: null,
        key: null,
        private: false,
        global: false
      }
    }
  },
  methods: {
    save(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.error = null;
      if (this.data.name !== null && this.data.key !== null) {
        console.log('1')
        if (this.id) {
          console.log('3')
          this.$services['category'].upsert(this.id,this.data).then(
              () => this.$router.push({name: 'Admin_Category'})
          );
        } else {
          console.log('2')
          this.$services['category'].insert({ ... this.data }).then(
              () => this.$router.push({name: 'Admin_Category'})
          );
        }
      } else {
        this.error = "Je moet minimaal een naam en key hebben.";
      }
      return false;
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(to) {
        if (to !== null) {
          this.$services['category'].get(to).then(r => this.data = { ...r })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>