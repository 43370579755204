<template>
  <button type="button" :class="[background, buttonSize]" class="relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200" role="switch" aria-checked="false" @click="toggle">
    <span :class="[slideBullet,bullet]" class="pointer-events-none relative inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
      <span :class="{'opacity-0 ease-out duration-100': v, 'opacity-100 ease-in duration-200': v}" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true" v-if="!v">
        <slot name="icon-off" />
      </span>
      <span :class="{'opacity-100 ease-in duration-200': v, 'opacity-0 ease-out duration-100': v}" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true" v-if="v">
        <slot name="icon-on" />
      </span>
    </span>
  </button>
</template>

<script>
export default {
  name: "BaseToggle",
  props: {
    on: {
      required: true,
      type: String
    },
    off: {
      required: false,
      default: 'gray-100',
      type: String
    },
    value: {
      required: true,
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
      validator: (prop) => [
        'xs','sm','md','lg','xl'
      ].includes(prop),
      default: 'md',
    }
  },
  data() {
    return {
      v: false,
    }
  },
  methods: {
    toggle() {
      this.v = !this.v;
    }
  },
  computed: {
    background() {
      return (this.v === true) ? `bg-${this.on}` : `bg-${this.off}`;
    },
    buttonSize() {
      switch(this.size) {
        case 'xs': return 'h-3 w-5';
        case 'sm': return 'h-5 w-9';
        case 'lg': return 'h-8 w-16';
        case 'xl': return 'h-12 w-24';
        case 'md': default: return 'h-6 w-12';
      }
    },
    bullet() {
      switch(this.size) {
        case 'xs': return 'h-2 w-2';
        case 'sm': return 'h-4 w-4';
        case 'lg': return 'h-7 w-7';
        case 'xl': return 'h-11 w-11';
        case 'md': default: return 'h-5 w-5';
      }
    },
    slideBullet() {
      if (this.v === false) {
        return 'translate-x-0';
      } else {
        switch(this.size) {
          case 'xs': return 'translate-x-2';
          case 'sm': return 'translate-x-4';
          case 'lg': return 'translate-x-8';
          case 'xl': return 'translate-x-12';
          case 'md': default: return 'translate-x-6';
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.v = to;
      }
    },
    v: {
      immediate: true,
      handler(to) {
        this.$emit('input', to);
      }
    }
  }
}
</script>

<style scoped>

</style>