<template>
  <div :class="[variant, 'alert']" v-bind="$attrs" v-on="$listeners" v-if="!isEmpty">
    <div>
      <div v-if="$slots.icon"><slot name="icon"></slot></div>
      <div><slot /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    variant: {
      type: String,
      required: false,
      default: 'info',
      validator: value => ['info','success','error','warning' ].includes(value),
    },
  },
  computed: {
    getComponent() {
      return (this.to) ? 'router-link' : 'button';
    },
    isBlock() {
      return (this.block !== false) ? 'inline-block w-full' : '';
    },
    isEmpty() {
      return this.$slots['default'] === undefined || this.$slots['default'][0].text === "";
    }
  }
}
</script>

<style scoped lang="scss">
.alert {
  @apply border-l-4  p-4 mb-3;
  > div {
    @apply flex;
    > div {
      &:first-child {
        @apply flex-shrink-0;
      }
      &:last-child {
        @apply ml-3;

        a {
          @apply font-medium underline;
        }
      }
    }
  }
  &.info {
    @apply bg-blue-50 border-blue-400;

    > div {
      @apply text-blue-700;

      a {
        @apply text-blue-700;
        &:hover {
          @apply text-blue-600;
        }
      }
    }
  }
  
  &.success {
    @apply bg-green-50 border-green-400;

    > div {
      @apply text-green-700;

      a {
        @apply text-green-700;
        &:hover {
          @apply text-green-600;
        }
      }
    }
  }
  
  &.warning {
    @apply bg-yellow-50 border-yellow-400;

    > div {
      @apply text-yellow-700;

      a {
        @apply text-yellow-700;
        &:hover {
          @apply text-yellow-600;
        }
      }
    }
  }

  &.error {
    @apply bg-red-50 border-red-400;

    > div {
      @apply text-red-700;

      a {
        @apply text-red-700;
        &:hover {
          @apply text-red-600;
        }
      }
    }
  }
}
</style>