<template>
  <div class="my-20">

    <base-alert variant="error" v-if="error" class="mb-10">{{ error.message }}</base-alert>

    <div class="grid lg:grid-cols-3 grid-cols-1 divide-x gap-0 mt-10">
      <div class="col-span-1">
        <div class="w-4/5 m-auto divide-y">
          <div>
            <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Aanmelden</div>
            <p class="text-gray-400 text-sm text-center">Heeft u al een account, vul dan hieronder uw e-mailadres en wachtwoord in om aan te melden.</p>

            <form @submit="signInWithCredentials" class="my-10 w-10/12 mx-auto">
              <base-input required type="email" v-model="login.email" name="email" :error="error" autocomplete="email">
                E-mailadres
                <template #prefix><font-awesome-icon icon="at" /></template>
              </base-input>
              <base-input required v-model="login.password" class="mt-3" type="password" name="password" :error="error">
                Wachtwoord
                <template #prefix><font-awesome-icon :icon="['fas','lock']" /></template>
                <template #explain><router-link :to="{name: 'PasswordForgotten'}">Wachtwoord vergeten?</router-link></template>
              </base-input>
              <base-checkbox v-model="login.keep" class="mt-3">Onthoud mij</base-checkbox>
              <base-button block class="mt-6">Inloggen</base-button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-span-1">
        <div class="w-4/5 m-auto">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Registreren</div>
          <p class="text-gray-400 text-sm text-center">Nog geen account? Vul hier onderstaande gegevens in om een account aan te maken.</p>
          <form @submit="register" class="my-10 w-10/12 mx-auto">
            <base-input required v-model="data.firstname">Voornaam</base-input>
            <base-input required v-model="data.lastname" class="mt-3">Achternaam</base-input>
            <base-input required v-model="data.birthdate" class="mt-3" type="date">
              Geboortedatum
              <template #prefix><font-awesome-icon :icon="['fas','calendar-day']" /></template>
            </base-input>
            <base-input required v-model="data.email" class="mt-3" type="email" name="email" :error="error">
              E-mailadres
              <template #prefix><font-awesome-icon icon="at" /></template>
            </base-input>
            <base-input required v-model="data.password" class="mt-3" type="password" name="password" :error="error">
              Wachtwoord
              <template #prefix><font-awesome-icon :icon="['fas','lock']" /></template>
              <template #explain>Minimaal 8 tekens lang</template>
            </base-input>
            <base-input required v-model="data.repassword" class="mt-3" type="password" name="repassword" :error="error">
              Wachtwoord nogmaals
              <template #prefix><font-awesome-icon :icon="['fas','lock']" /></template>
              <template #explain>Minimaal 8 tekens lang</template>
            </base-input>

            <base-button block class="mt-9">Registreren</base-button>
          </form>
        </div>
      </div>
      <div class="col-span-1">
        <div class="w-4/5 m-auto">
          <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-1/2 text-center pb-2 mx-auto">Social</div>
          <p class="text-gray-400 text-sm text-center">Heeft u een social account? Koppeling hem aan DagenTeller om snel en direct te registeren en aan te melden.</p>
          <div class="grid grid-cols-5 gap-3 mt-10 text-center">
            <div class="col-span-1">
              <font-awesome-icon @click="signInWithGoogle" :icon="['fab','google']" class="cursor-pointer text-gray-500 hover:text-red-500" size="3x"/>
            </div>
            <div class="col-span-1">
              <font-awesome-icon @click="signInWithFacebook" :icon="['fab','facebook']" class="cursor-pointer text-gray-500 hover:text-blue-900" size="3x"/>
            </div>
            <div class="col-span-1">
              <font-awesome-icon :icon="['fab','apple']" class="text-gray-200" size="3x"/>
            </div>
            <div class="col-span-1">
              <font-awesome-icon @click="signInWithMicrosoft" :icon="['fab','microsoft']" class="cursor-pointer text-gray-500 hover:text-blue-500" size="3x"/>
            </div>
            <div class="col-span-1">
              <font-awesome-icon :icon="['fab','twitter']" class="text-gray-200" size="3x"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      login: {
        email: localStorage.getItem("keepEmail") || null,
        password: null,
        keep: !!localStorage.getItem("keepEmail"),
      },
      data: {
        firstname: null,
        lastname: null,
        birthdate: '2000-01-01',
        email: null,
        password: null,
        repassword: null
      },
      error: null
    }
  },
  methods: {
    signInWithGoogle() {
      this.$store.dispatch("user/loginGoogle").then(() => {
        this.$router.push({
          name: 'Account_Daycounter',
        });
      }).catch(() => {
        this.error = this.$store.getters["user/getError"];
      });
    },

    signInWithFacebook() {
      this.$store.dispatch("user/loginFacebook");
    },

    async signInWithMicrosoft() {
      try {
        await this.$store.dispatch("user/loginMicrosoft");
        this.$router.push({
          name: 'Account_Daycounter',
        });
      } catch {
        this.error = this.$store.getters["user/getError"];
      }
    },

    signInWithCredentials(event) {
      this.error = null;
      event.preventDefault();
      event.stopImmediatePropagation();

      if (!this.validateEmail(this.login.email)) {
        this.error = this.error || {};
        this.error.email = true;
      }
      if (!this.validatePassword(this.login.password)) {
        this.error = this.error || {};
        this.error.password = true;
      }

      if (this.error === null) {
        localStorage.removeItem("keepEmail");
        if (this.login.keep) {
          localStorage.setItem("keepEmail", this.login.email);
        }

        this.$store.dispatch("user/login", this.login).then(() => {
          this.$router.push({
            name: 'Account_Daycounter',
          });
        }).catch(() => {
          this.error = this.$store.getters["user/getError"];
        });
      }
    },
    register(event) {
      this.error = null;

      event.preventDefault();
      event.stopImmediatePropagation();

      if (!this.validateEmail(this.data.email)) {
        this.error = this.error || {};
        this.error.email = true;
      }
      if (!this.validatePassword(this.data.password)) {
        this.error = this.error || {};
        this.error.password = true;
      }

      if (!this.validateRepassword(this.data.password, this.data.repassword)) {
        this.error = this.error || {};
        this.error.password = true;
        this.error.repassword = true;
      }


      if (this.error === null) {
        this.$store.dispatch('user/register', this.data).then(() => {
          this.$router.push({
            name: 'Account_Daycounter',
          });
        }).catch(() => {
          this.error = this.$store.getters["user/getError"];
        });
      }
    },

    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validatePassword(password) {
      return String(password).length >= 8;
    },
    validateRepassword(password, repassword) {
      return String(password) === String(repassword);
    }
  }
}
</script>

<style scoped lang="scss">
//::v-deep .fa-google {
//  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
//  -webkit-background-clip: text;
//  background-clip: text;
//  color: transparent;
//  -webkit-text-fill-color: transparent;
//}

</style>