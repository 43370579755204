import { initializeApp } from "firebase/app";
import 'firebase/firestore'
import { getFirestore } from "firebase/firestore/lite"

const firebaseConfig = {
    apiKey: "AIzaSyAKN_ACWGcuOGVDYM_qpKTrvNNjRg4DKP4",
    authDomain: "dagenteller.firebaseapp.com",
    databaseURL: "https://dagenteller.firebaseio.com",
    projectId: "dagenteller",
    storageBucket: "dagenteller.appspot.com",
    messagingSenderId: "1000236922547",
    appId: "1:1000236922547:web:6957a4e71bdcd210"
};

// Get a Firestore instance
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
//export const db = firebase.initializeApp(firebaseConfig).firestore()





//
// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";
// //import { getFirestore, collection } from "firebase/firestore/lite"
// //import 'firebase/firestore'
//
// // Your web app's Firebase configuration
//
// const firebaseApp = initializeApp(firebaseConfig);
// export default getDatabase(firebaseApp);
//
//
//
//
//

// utils
//const db = getFirestore(firebaseApp);
//const auth = firebase.auth()

// collection references
//const usersCollection = collection(db, 'users');

// export utils/refs
// export {
//     db,
//    // auth,
//     usersCollection,
// }

// Initialize Firebase
//const firebase = initializeApp(firebaseConfig);
//Object.defineProperty(Vue.prototype, '$firebase', { value: firebase });