<template>
  <div v-on="$listeners">
    <div>
      <label><input name="show" type="checkbox" v-model="data.show_after_date" :value="1"> Tonen nadat datum is verlopen</label>
    </div>

    <div>
      <label><input name="type" type="radio" v-model="data.type" :value="'date'"> Datum</label>
      <label><input name="type" type="radio" v-model="data.type" :value="'count'"> Teller</label>
      <label><input name="type" type="radio" v-model="data.type" :value="'total'"> Totalen</label>

      <base-input v-model="data.format">Format</base-input>
    </div>

    <br />

    <div v-if="data.type === 'date'">

      <label><input type="radio" v-model="data.setting" value="both" /> Begin en eind</label>
      <label><input type="radio" v-model="data.setting" value="start" /> Begin</label>
      <label><input type="radio" v-model="data.setting" value="end" /> Eind</label>

    </div>


    <div v-if="data.type === 'count'">
      <label v-for="countType in countTypes" :key="countType.type"><input name="count" type="radio" v-model="data.count" :value="countType.type">
        {{ countType.label }}</label>
    </div>

    <br />

    <div>
      <label><input name="image_position" type="radio" v-model="data.position" :value="0"> Dagenteller links</label>
      <label><input name="image_position" type="radio" v-model="data.position" :value="1"> Dagenteller rechts</label>
    </div>

    <div>
      <label><input type="checkbox" v-model="data.flex_left" :value="1" /> Dagenteller flex</label>
      <label><input type="checkbox" v-model="data.flex_right" :value="1" /> Tekst flex</label>
    </div>

  </div>
</template>

<script>
export default {
  name: "SettingsTextDaycounter",
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      countTypes: [
        {type: 'years', label: 'Jaar'},
        {type: 'months', label: 'Maand'},
        {type: 'weeks', label: 'Week'},
        {type: 'days', label: 'Dag'},
        {type: 'hours', label: 'Uur'},
        {type: 'minutes', label: 'Minuut'},
        {type: 'seconds', label: 'Seconde'},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
label {
  @apply block;
}
</style>