<template>
  <component :type="type" :is="getComponent" :to="to" :class="[variant, size, isBlock, 'text-sm']" :disabled="disabled" v-bind="$attrs" v-on="$listeners">
    <slot name="icon"></slot>
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    variant: {
      type: String,
      required: false,
      default: 'default',
      validator: (value) => ['default', 'create','update','delete','info','success','error','warning','light' ].includes(value),
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: value => ['xs','sm','md','lg','xl' ].includes(value),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      requird: false,
      validator: value => { return ['button','submit','reset' ].indexOf(value) !== -1 },
    },
    to: {
      type: Object,
      required: false
    },
    block: {
      required: false,
      default: false
    }
  },
  computed: {
    getComponent() {
      return (this.to) ? 'router-link' : 'button';
    },
    isBlock() {
        return (this.block !== false) ? 'inline-block w-full' : '';
    }
  }
}
</script>

<style scoped lang="scss">
button,
a {
  @apply text-white items-center border border-transparent shadow-sm rounded-md;
  &:disabled {
    @apply cursor-default text-opacity-50;
  }
  &:focus {
    @apply outline-none ring-1;
  }
  &.blue,
  &.info {
    @apply bg-blue-600;
    &:disabled {
      @apply bg-opacity-75;
    }
    &:not([disabled]) {
      &:hover {
        @apply bg-blue-700;
      }

      &:focus {
        @apply ring-blue-500;
      }
    }
  }
  &.light {
    @apply bg-gray-200 text-gray-600;
    &:disabled {
      @apply bg-opacity-75;
    }
    &:not([disabled]) {
      &:hover {
        @apply bg-gray-300;
      }

      &:focus {
        @apply ring-gray-400;
      }
    }
  }
  &.delete {
    @apply bg-red-600;
    &:disabled {
      @apply bg-opacity-75;
    }
    &:not([disabled]) {
      &:hover {
        @apply bg-red-700;
      }

      &:focus {
        @apply ring-red-500;
      }
    }
  }
  &.success {
    @apply bg-green-600;
    &:disabled {
      @apply bg-opacity-75;
    }
    &:not([disabled]) {
      &:hover {
        @apply bg-green-700;
      }

      &:focus {
        @apply ring-green-500;
      }
    }
  }
  &.warning {
    @apply bg-yellow-400;
    &:disabled {
      @apply bg-opacity-75;
    }
    &:not([disabled]) {
      &:hover {
        @apply bg-yellow-500;
      }

      &:focus {
        @apply ring-yellow-500;
      }
    }
  }
  &.default {
    @apply bg-slate-700;
    &:disabled {
      @apply bg-opacity-75;
    }
    &:not([disabled]) {
      &:hover {
        @apply bg-slate-800;
      }

      &:focus {
        @apply ring-slate-900;
      }
    }
  }
  &.create {
    @apply bg-green-400;
  }

  &.xs {
    @apply text-xs leading-3 font-normal px-2 py-1;
  }
  &.sm {
    @apply text-sm leading-3 font-normal px-2.5 py-1.5;
  }
  &.md {
    @apply text-sm leading-4 font-medium px-3 py-2;
  }
  &.lg {
    @apply text-base leading-5 font-medium px-3.5 py-2.5;
  }
  &.xl {
    @apply text-lg leading-5 font-medium px-5 py-3.5;
  }
}
</style>