<template>
  <div>Iets vergeten...</div>
</template>

<script>
// @TODO: nog volledig maken..
export default {
  name: "PasswordForgotten"
}
</script>

<style scoped>

</style>