<template>
  <div>
    <div class="">
      <nav class="bg-orange-700 text-white py-2 px-3">
        <router-link :to="{name: 'Home'}" class="px-3">Home</router-link>
        <router-link :to="{name: 'Admin'}" class="px-3">Admin</router-link>
      </nav>

      <nav class="bg-orange-400 p-3">
        <router-link class="px-3" :to="{name: 'Admin_Daycounter'}">Dagentellers</router-link>
        <router-link class="px-3" :to="{name: 'Admin_Category'}">Categorien</router-link>
      </nav>


    </div>
    <router-view class="p-6"></router-view>
  </div>
</template>

<script>
export default {
  name: "Admin"
}
</script>

<style scoped>

</style>