import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from "vue-axios";

import { axios } from '@/plugins';
import '@/directives';
import '@/filters';
import './assets/tailwind.css'
import '@/components';

Vue.config.productionTip = false

import './assets/tailwind.css';
import store from './store'

//hack to strip all console logs from production
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

/**
 * TODO: logo
 * TODO: nieuws item
 * TODO: Categorie overview
 * TODO: feature voting system
 * TODO: gerelateerde dagentellers
 *
 */

Vue.use(VueAxios, axios);

store.dispatch("user/detect").then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
