import axios from "axios"

const api = axios.create(
//     {
//     headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         "Cache-Control": "no-cache"
//     }
// }
);

export default api;