<template>

  <nav class="bg-slate-800 text-white">
    <div class="container mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <font-awesome-icon icon="bars" v-if="!mobileMenu" @click="mobileMenu = true" />
            <font-awesome-icon icon="xmark" v-else @click="mobileMenu = false" />
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <span>DT</span>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <a @click="goto({name: 'Home'})" class="cursor-pointer bg-gray-900 text-white px-3 py-2 rounded-md font-medium" aria-current="page">Home</a>
              <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium">Populair</a>
              <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium">Nieuws</a>
              <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium">Categorie&euml;n</a>
              <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium">Verjaardagen</a>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div v-if="!isAuthenticated" class="flex space-x-4">
              <a @click="goto({name: 'Login'})" class="bg-gray-800 px-3 py-2 flex rounded-full focus:outline-none focus:ring-0">
                Inloggen
              </a>
              <a @click="goto({name: 'Login'})" class="bg-gray-800 px-3 py-2 flex rounded-full focus:outline-none focus:ring-0">
                Registreren
              </a>
            </div>
            <div v-else>
              <div class="flex cursor-pointer" @click="openAccountPopup">
                <div class="rounded-full h-8 w-8 bg-slate-500 inline-block mr-3 bg-center bg-contain bg-no-repeat"
                     :style="{backgroundImage: `url('${profilePicture}')`}"
                >
                </div>
                <div class="leading-8">
                  {{ username }}
                  <font-awesome-icon class="ml-2" :icon="['fas','ellipsis-v']" />
                </div>
              </div>
            </div>

            <div @focusout="settings = false" ref="account_popup" v-show="settings" class="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <div v-if="isAdmin" class="cursor-pointer text-white block px-4 py-2 text-sm bg-orange-700 hover:bg-orange-500" @click="popupClick({name: 'Admin'})">Admin</div>
              <div class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:bg-slate-200" role="menuitem" @click="popupClick({name: 'Account_Settings'})">Account instelling</div>
              <div class="cursor-pointer text-gray-700 block px-4 py-2 text-sm hover:bg-slate-200" role="menuitem" @click="popupClick({name: 'Account_Daycounter'})">Persoonlijke dagentellers</div>
              <div class="cursor-pointer text-gray-700 block px-4 py-2 text-sm cursor-pointer border-t border-gray-100 hover:bg-slate-200" @click="signout">Afmelden</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden" id="mobile-menu" v-if="mobileMenu">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <a @click="goto({name: 'Home'})" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Home</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Populair</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Nieuws</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Categorie&euml;n</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Verjaardagen</a>
      </div>
    </div>
  </nav>

</template>

<script>
export default {
  name: "Navigation",
  computed: {
    isAuthenticated() {
      return this.$store.getters['user/isAuthenticated'];
    },
    username() {
      return this.$store.getters['user/getUsername'];
    },
    profilePicture() {
      return this.$store.getters['user/getPicture'];
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin'];
    }
  },
  data() {
    return {
      settings: false,
      mobileMenu: false
    }
  },
  methods: {
    goto(route) {
      this.mobileMenu = false;
      this.$router.push(route);
    },
    openAccountPopup() {
      this.settings = !this.settings;

      this.$nextTick(function() {
        this.$refs["account_popup"].focus();
      });
    },
    signout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({
          name: 'Home'
        })
      })
    },
    popupClick(route) {
      this.settings = false;
      this.$router.push(route);
    }
  }
}
</script>