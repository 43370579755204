<template>
  <div :class="{'error':hasError}">
    <label v-if="$slots.default"><slot /></label>
    <textarea :value="value" @input="updateValue" :class="[extra_class]"></textarea>
    <p class="mt-2 text-sm text-gray-600" v-if="$slots.postfix"><slot name="postfix" /></p>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    value: {
      required: false,
      type: [ String, Number]
    },
    extra_class: {
      required:false,
      default: ''
    },
    required: {
      required: false,
      default: false,
      type: [ String, Boolean ]
    },
    name: {
      type: String,
      required: false
    },
    error: {
      required: false,
      default: null,
    },
  },
  computed: {
    hasError() {
      return (this?.name && this.error !== null && Object.prototype.hasOwnProperty.call(this.error, this.name) && this.error[this.name] === true);
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input',event.target.value);
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  label {
    @apply text-red-600;
  }
  textarea {
    @apply border-red-600;
  }
  p {
    @apply text-red-400;
  }
}
label {
  @apply block text-sm font-medium text-gray-700;
}
textarea {
  @apply shadow-sm block w-full border border-gray-300 rounded-md;
  &:focus {
    @apply ring-indigo-500 border-indigo-500;
  }
}

</style>