<template>
  <div>
    <template v-for="type in types">
      <span v-if="precise[type]" :key="type">
        {{ precise[type] }} {{ type | timeLabel(precise[type]) }}{{ getSpacer(type) }}
      </span>
    </template>
  </div>
</template>

<script>
import intervalToDuration from "date-fns/intervalToDuration";
import toDate from "date-fns/toDate";
import isValid from "date-fns/isValid";

export default {
  name: "Precise",
  props: {
    start: {
      required: true
    },
    end: {
      required: false,
      default: null,
    }
  },
  data() {
    return {
      types: [
        'years','months','weeks','days','hours','minutes','seconds'
      ],
      precise: {},
      startDate: null,
      endDate: null,
    }
  },
  mounted() {
    if (this.end === null) {
      setInterval(() => {
        this.endDate = new Date();
        this.getPrecise();
      }, 500);
    }
  },
  methods: {
    getPrecise() {
      if (this.startDate && isValid(this.startDate) && this.endDate && isValid(this.endDate)) {
        this.precise = intervalToDuration({end: toDate(this.endDate), start: toDate(this.startDate)});
      }
      return this.precise;
    },
    getSpacer(total) {
      let tmp = this.precise;
      delete tmp.firstDateWasLater;

      let array = [];
      for(const [key, value] of Object.entries(tmp)) {
        if (value) {
          array.push(key);
        }
      }

      if (array.indexOf(total) !== array.length - 1) {
        return ',';
      }

      return '';

    },
  },
  watch: {
    start: {
      immediate: true,
      handler(to) {
        this.startDate = to;
        this.getPrecise();
      }
    },
    end: {
      immediate: true,
      handler(to) {
        this.endDate = to;
        this.getPrecise();
      }
    }
  }
}
</script>

<style scoped>

</style>