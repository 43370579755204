import parseISO from "date-fns/parseISO";
import isDate from "date-fns/isDate";
import toDate from "date-fns/toDate";
import isValid from "date-fns/isValid";

const formatDate = function(d) {
    if (isDate(d) && isValid(d)) {
        return d;
    }
    let date = parseISO(d);

    if (!isDate(date) || !isValid(date)) {
        date = toDate(d);
    }

    if (!isDate(date) || !isValid(date)) {
        if (d?.seconds) {
            date = toDate(d.seconds * 1000);
        }
    }

    if (isDate(date) && isValid(date)) {
        return date;
    }
    return d;
}

export default formatDate;