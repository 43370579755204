<template>
  <div>

    <div class="flex justify-between">
      <div class="leading-6 text-xl font-medium">Overzicht</div>
      <div class="mb-6">
        <base-button variant="success" :to="{name: 'Admin_Daycounter_Create'}">Toevoegen</base-button>
      </div>
    </div>

    <base-alert variant="error" v-if="error">{{ error }}</base-alert>

    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Naam</th>
          <th>Category</th>
          <th>Start</th>
          <th>Eind</th>
          <th>Items</th>
          <th>Frontpage</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, id) in data" :key="item.id" :class="{'process': item.process}">
          <td>{{ item.id || id}}</td>
          <td>{{ item.title}}</td>
          <td>
            <span v-for="c in getCategory(item.category)" :key="c.id" class="category">
              {{ c.name }}
            </span>
          </td>
          <td>{{ item.start || item.startDate}}</td>
          <td>{{ item.end || item.endDate}}</td>
          <td>{{ item.data ? item.data.length : '---' }}</td>
          <td>{{ item.frontpage }}</td>
          <td class="action">
            <base-button :disabled="item.process" :to="{name: 'Admin_Daycounter_Update', params: { id: item.id }}">Edit</base-button>
            <base-button :variant="'delete'" :disabled="item.process" @click="deleteItem(item.id, id)">Delete</base-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      data: [],
      category: [],
      error: null
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$services['category'].getAll().then(r => this.category = r);
      this.$services['dayCounter'].getAll({ global: true }).then(r => {
        r.map(item => item.process = false);
        this.data = [ ...r ];
      });
    },
    deleteItem(id, index) {
      this.data[index].process = true;
      this.error = null;
      if (id) {
        this.$services['dayCounter'].delete(id).then(this.load)
      } else {
        this.data[index].process = false;
        this.error = `Cannot find ID ${id}`;
      }
    },
    getCategory(item) {
      return this.category.filter(c => item.includes(c.id)).sort((a,b) => (a.name > b.name) ? 1 : -1);
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  @apply min-w-full divide-y divide-gray-200;

  thead {
    @apply bg-gray-50;

    th {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }

  tbody {
    tr {
      &.process {
        td {
          @apply bg-red-500 text-white #{!important};
        }
      }
      td {
        @apply px-3 py-2 whitespace-nowrap text-sm text-gray-900;
      }
      &:nth-child(2n) {
        @apply bg-gray-50;
      }
      &:hover {
        @apply bg-yellow-100;
      }
    }
  }
}
.category {
  @apply p-1 text-xs bg-gray-600 text-white rounded mr-0.5 mb-0.5;
}
</style>