<template>
  <div class="date" :class="{ today: isToday }">
    <div class="grid grid-cols-1 lg:grid-cols-6 md:grid-cols-9 gap-4" v-if="!userInput">
      <div class="lg:col-span-4 md:col-span-6 col-span-1 border border-slate-200 rounded" @click="inputUserDate">
        <div class="bg-slate-800 py-3 px-4 inline-block rounded-l">
          <font-awesome-icon
            class="cursor-pointer text-gray-100"
            :icon="['far', 'calendar-alt']"
          ></font-awesome-icon>
        </div>
        <div class="p-3 inline-block" v-if="date && !userInput">
          <span>
            {{ date | format("PPPP") }}
          </span>
        </div>
      </div>

      <div class="lg:col-span-2 md:col-span-3 col-span-1 border border-slate-200 rounded flex relative">
        <div class="p-3 flex-grow text-right"
          v-if="date && !userInput"
          @click="userInputTime = !userInputTime"
        >
          <span v-if="date">
            <span v-if="isNow">
              {{ date | format("pp") }}
            </span>
            <span v-if="!isNow">
              {{ date | format("HH:mm") }}
            </span>
          </span>
        </div>
        <div class="bg-slate-800 py-3 px-4 flex-shrink rounded-r">
          <font-awesome-icon
              v-show="!userInput"
              class="cursor-pointer text-gray-100"
              :icon="['far', 'clock']"
          ></font-awesome-icon>
        </div>
        <div class="bg-gray-200 py-4 px-2 absolute z-50 top-12 mt-0.5 rounded-b-xl shadow-lg w-full border-l border-r border-b border-gray-400 border-opacity-50" v-if="userInputTime">
          <dagen-teller-time :date="date" :isNow="isNow" @set-time="setTime" @click.prevent.stop />
        </div>
      </div>
    </div>

    <div class="grid gap-0 grid-cols-1 is-input" v-if="userInput" @click.prevent.stop>
      <div class="col-span-1 flex border rounded" :class="{'border-sky-700':isValid,'border-red-500 bg-red-50':!isValid}">
        <div class="py-3 px-4 flex-shink" :class="{'bg-sky-700':isValid,'bg-red-500':!isValid}">
          <font-awesome-icon
            class="cursor-pointer text-white"
            :icon="['far', 'calendar-alt']"
          ></font-awesome-icon>
        </div>
        <div class="inline-block flex-grow">
        <input
          class="w-full border-none leading-8 focus:outline-none focus:ring-0 bg-transparent"
          ref="userInput"
          type="text"
          v-model.trim="userInputDate"
          @keydown="userKeyDown"
          :state="userInputValid"
        />
        </div>
        <div class="buttons are-small flex-shrink py-2.5 px-2">
          <base-button
            v-show="dateHasChanged"
            @click.prevent.stop="userInputEnter"
            variant="success"
            size="sm"
          >
            <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
          </base-button>
          <base-button
            @click.prevent.stop="userInputClear"
            variant="warning"
            size="sm"
          >
            <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
          </base-button>
          <span v-show="false && isToday" class="tag is-success">Vandaag</span>
        </div>
      </div>
      <div class="bg-gray-200 p-4 absolute z-50 top-12 mt-0.5 rounded-b-xl shadow-lg w-full border-l border-r border-b border-gray-400 border-opacity-50">
        <dagen-teller-calendar
          :date="date"
          :isNow="isNow"
          @setDate="setDate"
        ></dagen-teller-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import toDate from "date-fns/toDate";
import parse from "date-fns/parse";
import isDate from "date-fns/isDate";
import add from "date-fns/add";
import sub from "date-fns/sub";
import format from "date-fns/format";

import nl from "date-fns/locale/nl";
import {formatDate} from "@/plugins";

export default {
  name: "formInputDate",
  data() {
    return {
      isValid: true,
      useDate: null,
      userInput: false,
      userInputValid: true,
      userInputFormat: "P",
      userInputDate: null,
      userInputTime: false,
      date: Date.now(),
      interval: null,
      dateHasChanged: false,
      acceptedFormats: {
        formats: [
          "day-month-year",
          "day month year",
          "day/month/year",
          "weekday day-month-year",
          "weekday day month year",
          "weekday day/month/year",
          "weekday day-month",
          "weekday day month",
          "weekday day/month",
          "weekday, day-month-year",
          "weekday, day month year",
          "weekday, day/month/year",
          "weekday, day-month",
          "weekday, day month",
          "weekday, day/month",
          "day-month-year hour:minute",
          "day month year hour:minute",
          "day/month/year hour:minute",
          "weekday day-month-year hour:minute",
          "weekday day month year hour:minute",
          "weekday day/month/year hour:minute",
          "weekday, day-month-year hour:minute",
          "weekday, day month year hour:minute",
          "weekday, day/month/year hour:minute",
          "weekday hour:minute day-month-year",
          "weekday hour:minute day month year",
          "weekday hour:minute day/month/year",
          "weekday hour:minute, day-month-year",
          "weekday hour:minute, day month year",
          "weekday hour:minute, day/month/year",
          "day-month-year hour:minute:second",
          "day month year hour:minute:second",
          "day/month/year hour:minute:second",
          "weekday day-month-year hour:minute:second",
          "weekday day/month/year hour:minute:second",
          "weekday day month year hour:minute:second",
          "weekday, day-month-year hour:minute:second",
          "weekday, day/month/year hour:minute:second",
          "weekday, day month year hour:minute:second",
          "year",
          "weekday",
          "weekday hour:minute",
          "weekday hour:minute:second",
          "weekday, hour:minute",
          "weekday, hour:minute:second",
          "unix",
          "dayweek week",
          "dayweek week year",
          "week year",
          "days[d] year",
          "[d]days year",
          "other"
        ],
        keywords: {
          dayweek: ["e", "E"],
          weekday: ["dddd", "ddd", "dd", "d"],
          week: ["w", "ww"],
          days: ["DDD", "DDDD"],
          day: ["D", "DD"],
          month: ["M", "MM", "MMM", "MMMM"],
          year: ["Y", "YY", "YYYY"],
          hour: ["H", "HH"],
          minute: ["mm"],
          second: ["ss"],
          unix: ["x", "X"],
          other: [
            "LT",
            "LTS",
            "L",
            "l",
            "LL",
            "ll",
            "LLL",
            "lll",
            "LLLL",
            "llll"
          ]
        },
        replacements: {
          morning: {
            ochtend: "06:00"
          },
          afternoon: {
            middag: "12:00"
          },
          evening: {
            avond: "18:00"
          },
          night: {
            nacht: "0:00"
          },
          today: {
            vandaag: format(new Date(), "P", { locale: nl})
          },
          tomorrow: {
            morgen: format(add(new Date(), { days: 2 }), "P", { locale: nl})
          },
          yesterday: {
            gisteren: format(sub(new Date(), { days: 1}), "P", { locale: nl})
          },
          now: {
            nu: {
              date: format(new Date(), "Pp", { locale: nl}),
              isNow: true
            }
          }
        }
      },
      validateDateStrings: [],
      dateUntilTemplate: null,
      isNow: false,
    };
  },
  props: {
    value: {
      required: true
    },
    auto: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    inputUserDate() {
      this.userInput = true;
      this.userInputTime = false;
      this.userInputDate = format(this.date, this.userInputFormat, { locale: nl});
      this.$nextTick(() => this.$refs.userInput.select());
    },
    userKeyDown(key) {
      if (key.key === "Enter") {
        this.userInputEnter();
      }
      if (key.key === "Escape") {
        this.userInputCancel();
      }
    },
    userInputClear() {
      if (this.dateHasChanged) {
        this.isNow = true;
        this.data = new Date();
        this.dateHasChanged = false;
        this.userInputFormat = "P";
        this.userInputValid = true;

      }
      this.userInputCancel();
      this.isValid = true;
    },
    userInputCancel() {
      if (this.dateHasChanged === false) {
        this.userInputDate = null;
      }
      this.userInput = false;
    },
    userInputEnter() {
      // if (this.dateHasChanged === false) {
      //   this.userInputCancel();
      //   return true;
      // }
      let value = this.$refs.userInput.value;
      let newDate = null;

      this.isValid = true;

      // Replacements
      const replacements = Object.keys(this.acceptedFormats.replacements);
      for (const replacement of replacements) {
        const keys = this.acceptedFormats.replacements[replacement];
        for (const key in keys) {
            let replace = this.acceptedFormats.replacements[replacement][key];
            if (typeof replace === "object" && value.indexOf(key) !== -1) {
              if (Object.prototype.hasOwnProperty.call(replace, 'isNow')) {
                this.dateHasChanged = !replace.isNow;
              }
              replace = replace.date;
            }
            value = value.replace(key, " " + replace);
          }

      }
      value = value.replace(/ +(?= )/g, "").trim();
      this.validateDateStrings.some(validateDateFormat => {
        newDate = parse(value, validateDateFormat);
        if (newDate !== null && isDate(newDate)) {
          this.userInputFormat = validateDateFormat;
          return true;
        }
      });

      this.userInputValid = false;
      if (newDate !== null && isDate(newDate)) {
        // Check date
        this.userInputValid = true;
        this.userInput = false;
        this.isNow = false;
        this.date = newDate;

      } else {
        this.isValid = false;
        this.$nextTick(this.$refs.userInput.select());
      }
    },
    setDate(response) {
      clearInterval(this.interval);
      this.userInputValid = true;
      if (response?.close !== false) {
        this.userInput = false;
      }
      this.isNow = response.isNow;
      this.date = response.date;
      this.userInputFormat = response.format;
      //this.$emit("input", { date: this.useDate, isNow: response.isNow });

      this.dateHasChanged = true;

    },
    setTime(response) {
      clearInterval(this.interval);
      this.userInputDate = true;
      this.isNow = response.isNow;
      this.date = formatDate(response.date);
      this.dateHasChanged = !response.isNow;
      //this.$emit("input", { date: this.date, isNow: response.isNow });

      if (response.isNow) {
        this.userInputDate = null;
        this.userInputCancel();

        this.interval = setInterval(() => {
          this.date = new Date();
        }, 100);
      }
      if (response.close !== true) {
        this.userInputTime = false;
      }
    },
    validateDate() {
      //see that kind of format..?!
      let dateStrings = this.acceptedFormats.formats;
      const keywords = this.acceptedFormats.keywords;
      const keys = Object.keys(keywords);

      for (const key of keys) {
        dateStrings = this.replaceKeyword(dateStrings, key, keywords[key]);
      }
      this.validateDateStrings = dateStrings;
    },
    replaceKeyword(strings, key, keywords) {
      let returnStrings = [];
      strings.forEach(string => {
        if (string.indexOf(key) !== -1) {
          keywords.forEach(keyword => {
            returnStrings.push(string.replace(key, keyword));
          });
        } else {
          returnStrings.push(string);
        }
      });
      return returnStrings;
    },
    playInterval() {
      clearInterval(this.interval);
      if (this.isNow === true) {
        this.interval = setInterval(() => {
          this.isNow = true;
          this.date = new Date();
        }, 100);
      }
    },
    load() {
      if (this.auto) {
        clearInterval(this.interval);

        this.date = (this.value?.date) ? this.value.date : new Date();

        //this.date = (this.value?.date) ? this.value.date : new Date();
        //console.log(this.date, parseISO(this.date), 'date');

        if (this?.value?.isNow === undefined || this.value.isNow === true) {
          this.isNow = true;
          this.playInterval();
        }
        //   this.dateHasChanged = true;
        // }

        //this.validateDate();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  deactivated() {
    this.isValid = true;
  },
  computed: {
    isToday() {
      return (
        !this.dateHasChanged &&
        this.useDate &&
        Math.floor(this.useDate / 1000) ===
          Math.floor(Date.now() / 1000)
      );
    },
    hasTime() {
      const m = toDate(this.useDate);
      return (
        m.get("hour") ||
        m.get("minute") ||
        m.get("second") ||
        m.get("millisecond")
      );
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    date(to) {
      this.$emit("input", {
        date: to,
        isNow: this.isNow
      });
      this.playInterval();
    },
    // value: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.load();
    //   }
    // },
    auto: {
      handler() {
        this.load();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  @apply relative;
}
</style>
