<template>
  <Item v-on="$listeners" class="item">
    <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
        v-if="editor"
    >
      <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        Bold
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        Italic
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        Strike
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        H1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        H2
      </button>
    </bubble-menu>

    <editor-content class="ring-0 outline-none" :editor="editor" />
  </Item>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

import Item from "./Item";
export default {
  name: "ItemText",
  props: {
    data: {
      required: true,
      type: [ Object ],
    },
    focus: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null
    }
  },
  components: {Item, EditorContent, BubbleMenu},
  mounted() {
    const _self = this;
    this.editor = new Editor({
      editable: false,
      content: this.data.text || '<p>Vul hier een nieuwe tekst in...</p>',
      extensions: [
        StarterKit,
      ],
      onUpdate({ editor }) {
        _self.data.text = editor.getHTML();
      }
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  watch: {
    focus(to) {
      this.editor.setEditable(to);
    }
  }
}
</script>

<style scoped lang="scss">
.item:focus {
  @apply border-dotted border border-black;
}
::v-deep .ProseMirror {
  @apply ring-0 outline-none #{!important};
}
::v-deep .ProseMirror-focused {
  outline: none !important;
}
::v-deep h1 {
  @apply text-3xl font-bold;
}
::v-deep h2 {
  @apply text-xl font-bold;
}
.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>