<template>
  <div class="relative p-2" v-on="$listeners">
    <div class="tools">
      <div class="handle" @click.stop>
        <font-awesome-icon icon="arrows-alt" />
      </div>
      <div class="delete" @click.stop="$emit('remove')">
        <font-awesome-icon icon="trash-alt" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Item"
}
</script>

<style scoped lang="scss">
.tools {
  @apply absolute top-1 right-1 w-12 bg-white bg-opacity-50 rounded flex z-50;
  &:hover {
    @apply bg-opacity-100;
  }

  > div {
    @apply flex-grow text-center opacity-20;
    &:hover {
      @apply opacity-100;
    }
    &.handle {
      @apply cursor-move;
    }
    &.delete {
      @apply cursor-pointer;
    }
  }
}
</style>