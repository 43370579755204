<template>
  <div>
    <div v-if="data.type === 'date'">
        <span v-if="data.setting === 'both'">
        {{ start | moment(data.format) }} / {{ end | moment(data.format)}}
        </span>
      <span v-if="data.setting === 'start'">
        {{ start | moment(data.format) }}
        </span>
      <span v-if="data.setting === 'end'">
        {{ end | moment(data.format)}}
      </span>
    </div>
    <div v-if="data.type === 'count'">
      {{ getCount | number }} {{ data.count | timeLabel(getCount) }}
    </div>
    <div v-if="data.type === 'total'">
      <template v-for="total in totals">
        <span v-if="getDiff[total]" :key="total">
          {{ getDiff[total] }} {{ total | timeLabel(getDiff[total]) }}{{ getSpacer(total) }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import toDate from "date-fns/toDate";
import intervalToDuration from "date-fns/intervalToDuration";

export default {
  name: "DaycounterResult",
  data() {
    return {
      start: null,
      startInterval: null,
      end: null,
      endInterval: null,
      totals: [ 'years','months','weeks','days','hours','minutes','seconds']
    }
  },
  props: {
    data: {
      required: true,
      type: [ Object ],
    }
  },
  created() {
    this.setDates(this.data)
  },
  computed: {
    getCount() {

      let start = this.start,
          end = this.end;
      if (this.end < this.start) {
        start = this.end;
        end = this.start;
      }
      console.log(start.format('Y-M-D h:m:s'), end.format('Y-M-D h:m:s'), this.start.format('Y-M-D h:m:s'), this.end.format('Y-M-D h:m:s'));

      return end.diff(start, this.data.count);
    },
    getDiff() {
      let start = this.start,
          end = this.end;
      if (this.end < this.start) {
        start = this.end;
        end = this.start;
      }
      return intervalToDuration({ end, start});
    }
  },
  methods: {
    getSpacer(total) {
      let tmp = this.getDiff;

      let array = [];
      for(const [key, value] of Object.entries(tmp)) {
        if (value) {
          array.push(key);
        }
      }
      if (array.indexOf(total) !== array.length - 1) {
        return ',';
      }

      return '';

    },
    setDates(to) {
      this.clearIntervals();
      if (to.start !== null && to.start !== '') {
        this.start = toDate(to.start);
      } else {
        this.startInterval = setInterval(() => {
          this.setDate(new Date(), 'start');
        }, 500);
      }

      if (to.end !== null && to.end !== '') {
        this.end = toDate(to.end);
      } else {
        this.endInterval = setInterval(() => {
          this.setDate(new Date(), 'end');
        }, 500);
      }
    },
    setDate(to, type) {
      this[type] = to;
    },
    clearIntervals() {
      clearInterval(this.startInterval);
      clearInterval(this.endInterval);
    }
  },
  watch: {
    immediate: true,
    data: {
      deep: true,
      handler(to) {
        this.setDates(to);
      }
    }
  }
}
</script>

<style scoped>

</style>