import {
    getAuth, onAuthStateChanged,
    GoogleAuthProvider, FacebookAuthProvider, OAuthProvider,
    signInAnonymously, signInWithPopup, //signInWithCredential,
    //deleteUser,
    signInWithEmailAndPassword, createUserWithEmailAndPassword,
} from "firebase/auth";
import Vue from "vue";
import differenceInSeconds from "date-fns/differenceInSeconds";
import toDate from "date-fns/toDate";

export default {
    namespaced: true,
    state: {
        user: null,
        token: null,
        admin: false,
        error: null,
        attempts: 0,
        last_attempt: null,
        max_attempts: 3,
        daycounters: [], //store the daycounters that are created by this user..
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        getUsername: state => {
            return state.user?.displayName || `${state.user.firstname} ${state.user.lastname}`;
        },
        isAnonymous: state => {
            return state.user?.isAnonymous === true;
        },
        isAuthenticated: state => {
            return state.user !== null && (state.user?.isAnonymous === false || state.user?.isAnonymous === undefined);
        },
        getPicture: state => {
            return state.user?.profilePicture || null
        },
        isAdmin: state => {
            return state.user?.admin || false;
        },
        getError: state => {
            return state.error;
        },
        getUserDaycounters: state => {
            return state.daycounters
        }
    },
    mutations: {
        user: (state, user) => {
            state.user = {
                ...user
            };
        },
        token: (state, token) => {
            state.token = token;
        },
        logout: state => {
            state.user = null;
        },
        admin: (state, admin) => {
            state.admin = admin;
        },
        error: (state, error) => {
            state.error = error;
        },
        add_attempt: state => {
            state.attempts++;
        },
        reset_attempts: state => {
            state.attempts = 0;
        },
        date_attempt: state => {
            state.last_attempt = new Date();
        },
        daycounters: (state, payload) => {
            payload.map(l => {
                l.originUserId = l.userId;
            });
            state.daycounters = payload;
        }
    },
    actions: {
        listDaycounters: async ({commit}, payload) => {
            let list = await Vue.prototype.$services['dayCounter'].getAll({'userId': payload});
            commit('daycounters', list);
        },
        detect: ({ dispatch }) => {
            return new Promise(resolve => {
                const auth = getAuth();
                onAuthStateChanged(auth, (user) => {
                   if (user) {
                       dispatch("get", user).then(() => {
                           resolve(user);
                       });
                   } else {
                       resolve(user);
                   }
                });
                // Do something here... lets say, a http call using vue-resource
                // firebase.auth().onAuthStateChanged(user => {
                //     if (user) {
                //         commit("setUser", user);
                //         dispatch("get", user).then(() => {
                //             resolve(user);
                //         });
                //     } else {
                //         resolve(user);
                //     }
                // });
            });
        },
        token: ({ commit }) => {
            return new Promise(resolve => {
                const auth = getAuth();
                console.log(auth.currentUser, 'token');
                auth.currentUser.getIdToken(true).then(response => {
                        commit("token", response);
                        resolve(true);
                    });
            });
        },
        anonymous1: async ({ dispatch, commit }) => {
            const auth = getAuth();
            await signInAnonymously(auth);
            onAuthStateChanged(auth, async (user) => {
                console.log('ano', user);
                if (user) {
                    commit('user', {...user, uid: user.uid});
                    return await dispatch("create", {type: 'anonymous', ...user});
                }
            });

        },
        register: ({ commit, dispatch }, payload) => {
            return new Promise((resolve, reject) => {
                const auth = getAuth();
                createUserWithEmailAndPassword(auth, payload.email, payload.password)
                    .then((result) => {
                        commit("token", result.accessToken);

                        delete payload.password;
                        delete payload.repassword;

                        dispatch("create", { ...payload, ...result.user}).then(() => resolve)
                    })
                    .catch((error) => {
                        dispatch("loginError", error).then(() => {
                            reject(error);
                        });
                    });
            })

            // return new Promise((resolve, reject) => {
            //     const database = firebase.database();
            //     const user = database.ref("/users/" + payload.uid);
            //     user
            //         .set({
            //             displayName: payload.displayName,
            //             isAdmin: false,
            //             id: payload.uid,
            //             email: payload.email,
            //             profilePicture: payload.photoURL || null,
            //             type: payload.type || "custom",
            //             dateOfBirth: payload.birthDate || null,
            //             skipDateOfBirth: false
            //         })
            //         .then(() => {
            //             commit("setUser", user);
            //             resolve();
            //         })
            //         .catch(err => {
            //             reject(err);
            //         });
            // });
        },
        create: async ({dispatch}, payload) => {
            const birthDate = payload?.birthDate ?? payload?.birthdate ?? null;

            const data = {
                displayName: payload.displayName || null,
                firstname: payload.firstname || null,
                lastname: payload.lastname || null,
                id: payload.uid,
                email: payload.email || null,
                profilePicture: payload.photoURL || null,
                type: payload.type || "custom",
                dateOfBirth: (birthDate && birthDate.isValid) ? birthDate.format('YYYY-MM-DD') : null,
                dayOfBirth: (birthDate && birthDate.isValid) ? birthDate.format('DD') : null,
                monthOfBirth: (birthDate && birthDate.isValid) ? birthDate.format('MM') : null,
                yearOfBirth: (birthDate && birthDate.isValid) ? birthDate.format('YYYY') : null,
                uid: payload.uid
            }

            Vue.prototype.$services['user'].upsert(payload.uid, data);
            return await dispatch("get", data);
        },
        get: async ({ commit }, payload) => {
            const user = await Vue.prototype.$services['user'].get(payload.uid || payload.id);
            console.log('get user', user);
            if (user !== null) {
                commit('user', {...user, ...payload});
                return { ...user, ...payload };
            }
            return null;
        },
        // update: ({ state }, payload) => {
        //     return new Promise(resolve => {
        //         const ref = firebase.database().ref("/users/" + state.user.id);
        //         ref.update(payload, resolve);
        //     });
        // },
        login: ({ commit, dispatch, state }, payload) => {
            commit("error", null);
            return new Promise((resolve, reject) => {

                const time = (state.last_attempt) ? Math.abs(differenceInSeconds(toDate(state.last_attempt),new Date())) : 0
                const times = Math.pow(Math.floor(state.attempts / state.max_attempts), 2);

                if (state.attempts % state.max_attempts === 0 && state.last_attempt !== null && time < (times * 10)) {
                    const error = {message: 'U heeft te veel pogingen gedaan, om misbruik te voorkomen vragen wij u even te wachten.'};
                    dispatch("loginError", error).then(() => {
                        reject(error);
                    });
                } else {
                    commit('add_attempt');
                    commit('date_attempt');

                    const auth = getAuth();
                    signInWithEmailAndPassword(auth, payload.email, payload.password)
                        .then((result) => {
                            commit('reset_attempts');
                            commit("token", result.user.accessToken);
                            dispatch("get", result.user).then(r => resolve(r));
                        }).catch(error => {
                        dispatch("loginError", error).then(() => {
                            reject(error);
                        });
                    })
                }
            });
        },
        loginGoogle: ({ commit, dispatch, state }) => {
            commit("error", null);
            const provider = new GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
            return new Promise((resolve, reject) => {
                const auth = getAuth();
                signInWithPopup(auth, provider)
                    .then((result) => {
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;

                        commit("token", token);

                        dispatch("create", { ...result.user, type: "google" }).then(
                            () => {
                                resolve(state.user);
                            }
                        );

                    }).catch((error) => {
                        dispatch("loginError", error).then(() => {
                            reject(state.error);
                        });
                    });
            });
        },
        loginFacebook: async ({ commit, dispatch, state }) => {
            commit("error", null);

            const auth = getAuth();
            const provider = new FacebookAuthProvider();

            try {
                let result = await signInWithPopup(auth, provider);
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;

                commit("token", token);
                if (result.additionalUserInfo.isNewUser === true) {
                    // Store user
                    await dispatch("create", {...result.user, type: "facebook"});
                } else {
                    // Get user
                    await dispatch("get", result.user)
                }
                return state.user;
            } catch (error) {
                await dispatch("loginError", error);
                throw error;
            }

        },
        loginMicrosoft: async ({ commit, dispatch, getters, state }) => {
            commit("error", null);

            const auth = getAuth();
            const provider = new OAuthProvider('microsoft.com');

            provider.addScope('User.Read');

            let result;
            let credential;
            let anonymousUser = null;

            try {
                if (auth.currentUser && auth.currentUser.isAnonymous === true) {
                    anonymousUser = auth.currentUser;
                    await dispatch('listDaycounters', anonymousUser.uid);
                }
                result = await signInWithPopup(auth, provider);
                credential = OAuthProvider.credentialFromResult(result);
            } catch (error) {
                await dispatch("loginError", error);
                throw error;
            }

            const token = credential.accessToken;
            let user = await dispatch("get", result.user);

            commit("token", token);
            if (user === null) {
                // Store user
                await dispatch("create", { ...result.user, type: "microsoft" });
            }

            if (anonymousUser !== null) {
                getters['getUserDaycounters'].forEach(daycounter => {
                    Vue.prototype.$services['dayCounter'].update(daycounter.id, {
                        originUserId: anonymousUser.uid,
                        userId: state.user.uid
                    });
                });
                //await deleteUser(anonymousUser);
            }
            return state.user;

        },
        loginError: ({ commit }, payload) => {
            return new Promise(resolve => {
                const error = {
                    message: ''
                };
                commit("error", null);

                // // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.email;
                // // The AuthCredential type that was used.
                // const credential = GoogleAuthProvider.credentialFromError(error);

                switch (payload.code) {
                    case 'auth/email-already-exists':
                        error.message = 'The provided email is already in use by an existing user. Each user must have a unique email.';
                        error.email = true;
                        break;
                    case 'auth/id-token-expired':
                        return 'The provided Firebase ID token is expired.';
                    case 'auth/id-token-revoked':
                        return 'The Firebase ID token has been revoked.';
                    case 'auth/insufficient-permission':
                        return 'The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.';
                    case 'auth/internal-error':
                        return 'The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel.';
                    case 'auth/invalid-argument':
                        return 'An invalid argument was provided to an Authentication method. The error message should contain additional information.';
                    case 'auth/invalid-creation-time':
                        return 'The creation time must be a valid UTC date string.';
                    case 'auth/invalid-display-name':
                        return 'The provided value for the displayName user property is invalid. It must be a non-empty string.';
                    case 'auth/invalid-email':
                        return 'The provided value for the email user property is invalid. It must be a string email address.';
                    case 'auth/invalid-email-verified':
                        return 'The provided value for the emailVerified user property is invalid. It must be a boolean.';
                    case 'auth/invalid-id-token':
                        return 'The provided ID token is not a valid Firebase ID token.';
                    case 'auth/invalid-password':
                    case 'auth/user-not-found':
                    case 'auth/wrong-password':
                        error.password = true;
                        error.message =  'Combinatie van e-mailadres en wachtwoord is niet geldig. Controleer uw gegevens of registreer.';
                        error.email = true;
                        break;
                    case 'auth/email-already-in-use':
                        error.message =  'E-mailadres is gekoppeld aan een ander account, probeer aan te melden of gebruik een ander e-mailadres.';
                        error.email = true;
                        break;
                    case "auth/account-exists-with-different-credential":
                        error.message =
                            "Account (e-mailadres) is al reeds gebruikt via andere registratie methode (handmatig, Google, Facebook).";
                        error.email = true;
                        break;
                    default:
                        error.message = payload.message || "Fout opgetreden, inloggen mislukt.";
                }

                commit("error", { ...error , origin: payload });
                resolve();
            });
        },
        logout: ({ commit }) => {
            const auth = getAuth();
            auth.signOut().then(() => commit('logout'));
        }
    }
};
