<template>
  <div>
    <base-alert v-if="error" variant="error">{{ error }}</base-alert>
    <form @submit="save" class="flex">

      <div class="w-64 flex-none p-2">

        <draggable class="dragArea list-group"
            :list="list1" :sort="false"
            :group="{ name: 'people', pull: 'clone', put: false }"
            :clone="clone"
        >
          <div class="list-group-item" v-for="(element, index) in list1" :key="index">
            <component :is="`tile${element.component}`" class="mb-1" />
          </div>
        </draggable>


      </div>
      <div class="flex-grow bg-gray-300 email">
        <draggable
            class="dragArea list-group h-full"
            :list="list2"
            group="people"
            handle=".handle"
        >
          <div class="list-group-item" v-for="(element, index) in list2" :key="element.id">
            <component :is="element.component" @click.stop.prevent="settings(element, index)" class="item" :class="{'focussed': element.focus}" v-outside-click="timeoutUnfocus" :focus="element.focus" :data="element.data" @remove="remove(index)" />
          </div>
        </draggable>

        <div class="text-right">
          <BaseButton @click="save">Opslaan</BaseButton>
        </div>

      </div>
      <div class="flex-none w-80 p-2">
        Instellingen

        <component :is="`settings${element.component}`" :data="element.data" v-if="element" @click="settingFocus" @focus="settingFocus" v-outside-click="settingUnfocus"  />
        <div v-if="!element">
          <base-input v-model="title">Naam</base-input>
          <base-textarea v-model="intro">Intro tekst</base-textarea>
          <base-input type="date" v-model="start">Begindatum</base-input>
          <base-input events="keydown.enter.prevent" type="date" v-model="end">Einddatum</base-input>
          <base-select v-model="category" :data="categories" label="name" multiple size="10">Categorien</base-select>
          <base-checkbox v-model="frontpage">Tonen op homepage</base-checkbox>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import tileItemText from "@/components/admin/daycounter/tile/Text";
import tileItemImage from "@/components/admin/daycounter/tile/Image";
import tileItemImageText from "@/components/admin/daycounter/tile/ImageText";
import tileItemDaycounter from "@/components/admin/daycounter/tile/Daycounter";
import tileItemTextDaycounter from "@/components/admin/daycounter/tile/TextDaycounter";

import ItemText from "@/components/admin/daycounter/Text";
import ItemImageText from "@/components/admin/daycounter/ImageText";
import ItemImage from "@/components/admin/daycounter/Image";
import ItemDaycounter from "@/components/admin/daycounter/Daycounter";
import ItemTextDaycounter from "@/components/admin/daycounter/TextDaycounter";

import settingsItemText from "@/components/admin/daycounter/settings/Text";
import settingsItemImage from "@/components/admin/daycounter/settings/Image";
import settingsItemImageText from "@/components/admin/daycounter/settings/ImageText";
import settingsItemDaycounter from "@/components/admin/daycounter/settings/Daycounter";
import settingsItemTextDaycounter from "@/components/admin/daycounter/settings/TextDaycounter";

import toDate from "date-fns/toDate";

export default {
  name: "Add",
  components: {
    draggable,
    tileItemText, tileItemImage, tileItemImageText, tileItemDaycounter, tileItemTextDaycounter,
    ItemText, ItemImageText, ItemImage, ItemDaycounter, ItemTextDaycounter,
    settingsItemText, settingsItemImage, settingsItemImageText, settingsItemDaycounter, settingsItemTextDaycounter },
  data() {
    return {
      id: this.$route.params.id || null,
      error: null,
      categories: [],
      list1: [
        { component: 'ItemText', data: { text: null } },
        { component: 'ItemImage', data: { url: null } },
        { component: 'ItemImageText', data: { image_position: 0, text: null } },
        { component: 'ItemDaycounter', data: { start: null, end: null, setting: 'both', format: 'YYYY-MM-DD HH:mm:ss', type: 'date', count: null } },
        { component: 'ItemTextDaycounter', data: {
          position: 0, flex_left: 1, flex_right: 1, start: null, end: null, setting: 'both',
          format: 'YYYY-MM-DD HH:mm:ss', type: 'date', count: null, text: null
        } }
      ],
      list2: [],
      element: null,
      focusOnSetting: false,

      title: null,
      start: null,
      end: null,
      category: [],
      frontpage: false,
      intro: null,
    }
  },
  mounted() {
    this.$services['category'].getAll().then(r => {
      this.categories = r.slice().sort(function(a, b){
         return (a.name > b.name) ? 1 : -1;
      });
    });
  },
  methods: {
    clone(item) {
      let cloned = { ...item, data: {...item.data}, id: this.uuidv4(), focus: false }
      if (item.component === 'ItemDaycounter' || item.component === 'ItemTextDaycounter') {
        cloned.data = { ...cloned.data, start: this.start, end: this.end };
      }
      return cloned;
    },
    updateList2Items() {
      this.list2.forEach(item => {
        if (item.component === 'ItemDaycounter' || item.component === 'ItemTextDaycounter') {
          item.data = { ...item.data, start: this.start, end: this.end };
        }
      })
    },
    remove(i) {
      this.list2.splice(i, 1);
    },
    settings(item,index) {
      this.unfocus(false);
      this.element = item;

      let list = [ ...this.list2 ];
      list[index].focus = true;
      this.list2 = [ ...list ]

    },
    settingFocus() {
      this.focusOnSetting = true;
    },
    settingUnfocus() {
      this.focusOnSetting = false;
    },
    unfocus(force) {
      if (this.focusOnSetting === false) {
        this.focusOnSetting = false;
        this.list2.map(i => i.focus = false);
        this.element = null;

        if (false !== force) {
          this.$forceUpdate();
        }
      }
    },
    timeoutUnfocus() {
      setTimeout(this.unfocus, 100);
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    setData() {
      let start = toDate(this.start);
      console.log(start,'start');
      let tmp = {
        id: this.id || null,
        title: this.title,
        start: this.start,
        end: this.end,
        data: this.list2,
        category: this.category,
        frontpage: this.frontpage,
        intro: this.intro,
        global: true,
        start_day: start.format('D'),
        start_month: start.format('M'),
        start_year: start.format('YYYY')
      }

      this.category.forEach(c => {
        tmp[c] = true;
      })

      return tmp;
    },
    getData(r) {
        this.title = r.title;
        this.start = r.start;
        this.end = r.end;
        this.list2 = r.data;
        this.category = r.category;
        this.frontpage = r.frontpage;
        this.intro = r.intro;
    },
    save(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.error = null;
      if (this.title !== null && (this.start !== null || this.end !== null) && this.list2.length > 0) {
        if (this.id) {
          this.$services['dayCounter'].upsert(this.id,this.setData()).then(
              () => this.$router.push({name: 'Admin_Daycounter'})
          );
        } else {
          this.$services['dayCounter'].insert(this.setData()).then(
              () => this.$router.push({name: 'Admin_Daycounter'})
          );
        }
      } else {
        this.error = "Je moet minimaal een titel, begin OF eind datum invullen en het bericht moet een body hebben.";
      }
      return false;
    }
  },
  watch: {
    start() {
      this.updateList2Items();
    },
    end() {
      this.updateList2Items();
    },
    id: {
      immediate: true,
      handler(to) {
        if (to !== null) {
          this.$services['dayCounter'].get(to).then(r => this.getData(r))
        }
      }
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
}
</script>

<style scoped lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
/*.email {
  /deep/ .sortable-ghost {
    @apply border-2 border-dashed border-gray-600 ring-0 ring-opacity-0 #{!important};

    .tools {
      @apply hidden;
    }
  }
}*/
.focussed {
  @apply ring-1 ring-blue-500 #{!important};
}
.item {
  &:hover {
    @apply ring-1 ring-blue-300;
   }
}
</style>