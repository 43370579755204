<template>
  <Item v-on="$listeners">
    <div class="flex" :class="{'flex-row-reverse':data.image_position === 1}">
      <div class="flex-1">
        <div class="bg-gray-400 m-2 rounded-xl text-center py-10 text-gray-300" v-if="!data.url">
          Voeg url toe van afbeelding via instellingen
        </div>
        <img :src="data.url" v-if="data.url" class="w-full h-auto" />
      </div>
      <div class="flex-1">
        <bubble-menu
            class="bubble-menu"
            :tippy-options="{ duration: 100 }"
            :editor="editor"
            v-if="editor"
        >
          <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            Bold
          </button>
          <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            Italic
          </button>
          <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            Strike
          </button>
        </bubble-menu>

        <editor-content class="ring-0 outline-none" :editor="editor" />
      </div>
    </div>
  </Item>
</template>

<script>
import Item from "./Item";
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  name: "ItemImageText",
  props: {
    data: {
      required: true,
      type: [ Object ],
    },
    focus: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null
    }
  },
  components: {Item, EditorContent, BubbleMenu},
  mounted() {
    const _self = this;
    this.editor = new Editor({
      editable: false,
      content: this.data.text || '<p>Vul hier een nieuwe tekst in...</p>',
      extensions: [
        StarterKit,
      ],
      onUpdate({ editor }) {
        _self.data.text = editor.getHTML();
      }
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  watch: {
    focus(to) {
      this.editor.setEditable(to);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .ProseMirror {
  @apply ring-0 outline-none #{!important};
}
::v-deep .ProseMirror-focused {
  outline: none !important;
}
.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>