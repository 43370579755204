import { collection, doc, getDoc, deleteDoc, getDocs, setDoc, updateDoc, query, where, limit, orderBy} from "firebase/firestore/lite";
import {db} from "@/plugins/firebase";

export default resource => ({
    async getAll(filter, order, limit) {
        let col = collection(db, resource);
        col = this.setFilter(col, filter);
        col = this.setOrder(col, order);
        col = this.setLimit(col, limit);

        let result = [];
        let response = await getDocs(col);
        response.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
        })

        return await result;
    },
    async get(id) {
        const col = collection(db, resource);
        const docRef = doc(col, id);
        let response = await getDoc(docRef);

        if (response.exists()) {
            return await { ...response.data(), id: response.id };
        }
        return null;
    },

    async insert(data) {
        const d = doc(collection(db, resource));
        await setDoc(d, { ...data, id: d.id });
        return d;
    },

    async update(id, data) {
        return await updateDoc(doc(db, resource, id), data);
    },

    async upsert(id, data) {
        return await setDoc(doc(db, resource,id), data, { merge: true});
    },

    async delete(id) {
        return await deleteDoc(doc(db, resource, id));
    },

    setFilter(col, ff) {
        let filter = ff;
        if (ff) {
            if (Object.prototype.toString.call(ff) !== '[object Array]') {
                filter = [];
                filter.push(ff);
            }
            filter.forEach(f => {
                let keys = Object.keys(f);
                col = query(col, where(keys[0], f.operator || '==',f[keys[0]]));
            })
        }
        return col;
    },

    setOrder(col, order) {
        if (order) {
            if (typeof order === 'string') {
                col = query(col, orderBy(order))
            }
        }
        return col;
    },

    setLimit(col, l) {
        if (l) {
            if (typeof l === 'number') {
                col = query(col, limit(l));
            }
        }
        return col;
    }
})