<template>
  <div v-on="$listeners">
    <p>URL</p>
    <BaseInput v-model="data.url" />
  </div>
</template>

<script>
export default {
  name: "SettingsImage",
  props: {
    data: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>