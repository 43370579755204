<template>
  <div class="border border-gray-100 rounded-md p-2">
    <div class="bg-gray-200 w-1/5 h-2 mr-1.5 inline-block rounded-sm"></div>
    <div class="bg-gray-200 w-1/5 h-2 mr-1.5 inline-block rounded-sm"></div>
    <div class="bg-gray-200 w-2/5 h-2 mr-1.5 inline-block rounded-sm"></div>
    <p>Dagenteller element</p>
  </div>
</template>

<script>
export default {
  name: "ItemDaycounter"
}
</script>

<style scoped>

</style>