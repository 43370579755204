<template>
  <div :class="{'error':hasError}">
    <label v-if="$slots.default">
      <slot />
    </label>
    <div class="mt-1 flex rounded-md shadow-sm">
      <span class="pre inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-100 text-gray-500" v-if="$slots.prefix">
        <slot name="prefix"/>
      </span>
      <input :required="isRequired" v-on:[events]="handler" :type="type"
             :value="value" @input="updateValue" :class="[{'prefix':$slots.prefix},extra_class]" v-bind="$attrs"
             class="flex-1 block w-full min-w-0 rounded-md border border-gray-300 text-sm px-3 py-2"
      />
    </div>
    <div v-if="$slots.explain" class="explain text-sm text-gray-400 mt-1" :class="{'ml-11':$slots.prefix}">
      <slot name="explain" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    value: {
      required: false,
      type: [ String, Number]
    },
    type: {
      required: false,
      default: 'text',
      validator: (prop) => [
        'text', 'date', 'password', 'number', 'email', 'hidden', 'search', 'tel', 'url','search'
      ].includes(prop)
    },
    required: {
      required: false,
      default: false,
      type: [ String, Boolean ]
    },
    events: {
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false
    },
    error: {
      required: false,
      default: null,
    },
    extra_class: {
      required:false,
      default: ''
    }
  },
  computed: {
    isRequired() {
      return this.required === '' || this.required === true || this.required === 'required' || this.required === 'true';
    },
    hasError() {
      return (this?.name && this.error !== null && Object.prototype.hasOwnProperty.call(this.error, this.name) && this.error[this.name] === true);
    }
  },
  methods: {
    handler() {

    },
    updateValue(event) {
      this.$emit('input',event.target.value);
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  label {
    @apply text-red-600;
  }
  .pre {
    @apply bg-red-50 border-red-600 text-red-400;
  }
  input {
    @apply border-red-600;
  }
  .explain {
    @apply text-red-400;
  }
}
input {
  &.prefix {
    @apply rounded-none rounded-r-md;
  }
  &:focus {
    @apply ring-indigo-500 border-indigo-500
  }
}
label {
  @apply block text-sm font-medium text-gray-700;
}
.explain {
  a {
    @apply text-blue-400 underline;
    &:hover {
      @apply no-underline;
    }
  }
}
</style>