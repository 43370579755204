<template>
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:py-20 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <font-awesome-icon icon="check" size="xl" :class="`text-${variantColour}-500`" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  <slot name="title"></slot></p>
                <p class="mt-1 text-sm text-gray-500">
                  <slot></slot>
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button type="button" @click="$emit('close')" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0">
                  <span class="sr-only">Close</span>
                  <font-awesome-icon icon="times" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Base",
  props: {
    show: {
      required: true
    },
    variant: {
      required: false,
      default: "success"
    }
  },
  computed: {
    variantColour() {
      if (this.variant === 'success') {
        return 'green'
      }
      return '';
    }
  }
}
</script>

<style scoped>

</style>