import Vue from "vue";

import BaseAlert from "./alert/Base";

import BaseButton from "./form/button/Base";
import BaseCheckbox from "./form/checkbox/Base";
import BaseInput from "./form/input/Base";
import formInputDate from "./form/input/Date";
import BaseSelect from "./form/select/Base";
import SelectMonth from "./form/select/Month";
import BaseTextarea from "./form/textarea/Base";
import BaseToggle from "./form/toggle/Base";

import DagenTellerCalendar from "./Calendar";
import DagenTellerTime from "./Time";

Vue.component('base-alert', BaseAlert);
Vue.component('base-button', BaseButton);
Vue.component('base-checkbox', BaseCheckbox);
Vue.component('base-input', BaseInput);
Vue.component('form-input-date', formInputDate);
Vue.component('base-select', BaseSelect);
Vue.component('select-month', SelectMonth);
Vue.component('base-textarea', BaseTextarea);
Vue.component('base-toggle', BaseToggle);
Vue.component('dagen-teller-calendar', DagenTellerCalendar);
Vue.component('dagen-teller-time', DagenTellerTime);