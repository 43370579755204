<template>
  <div class="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
    <div class="col-span-2 p-4 md:p-0">
      <form-calculator :value="calculation" :loading="loading" @submit="calculate">
        <template #header>
          <p class="text-xl font-medium text-gray-900">Maak een DagenTeller</p>
          <p class="mt-1 text-sm text-gray-500 ">Begin met het samenstellen van uw dagenteller, begin met de startdatum</p>
        </template>
      </form-calculator>
    </div>
    <div class="col-span-1">

      <div class="mb-4 pb-4 border-b border-gray-200">
        <p class="text-lg font-medium text-gray-900">Verjaardagen</p>
        <div class="space-y-3 mt-4">
          <div v-for="daycounter in birthdays" :key="daycounter.id" class="grid grid-cols-3 gap-4">
            <skeleton class="col-span-2">{{ daycounter.title }}</skeleton>
            <skeleton class="col-span-1 text-right">{{ daycounter.start | momentDiff('Y')}}<template #post> jaar</template></skeleton>
          </div>
        </div>
        <div class="mt-4 text-sm text-gray-600">Ken jij iemand die jarig is vandaag? Maak een dagenteller aan!</div>
      </div>

      <div class="">
        <p class="text-lg font-medium text-gray-900">Vakanties &amp; feestdagen</p>
        <div v-for="daycounter in holidays" :key="daycounter.id" class="mt-4">
          <skeleton class="font-medium">{{ daycounter.title }}</skeleton>
          <skeleton class="text-gray-500 text-sm mb-2" variant="text">{{daycounter.intro}}</skeleton>
          <div class="bg-white rounded-xl border border-slate-200">
            <div class="bg-slate-500 text-white text-xs rounded-xl py-1 px-2 text-center">
              {{ daycounter.start | moment('DD MMMM YYYY')}} <span class="text-opacity-50 text-white">tot en met</span> {{daycounter.end | moment('DD MMMM YYYY')}}
            </div>
            <div class="text-center text-xs py-1 text-gray-500">
              <daycounter-precise :start="daycounter.start" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div v-for="daycounter in globalDaycounters" :key="daycounter.id">
        {{ daycounter.title }}
      </div>
    </div>

  </div>
</template>

<script>
import format from "date-fns/format";

export default {
  name: "Frontpage",
  data() {
    return {
      date: null,
      day: null,
      month: null,
      year: null,

      calculation: {
        start: null,
        end: null,
        type: false,
        amount: [{
          value: 0,
          type: 'days',
          subtract: false
        }],
      },
      categories: [],
      globalDaycounters: [],
      birthdays: 3,
      holidays: null,
      loading: false

    }
  },

  methods: {
    slack() {
      let slack = this.$slack;
      let x = slack.addText('TEST');
      x.action('user');
      x.send();
    },
    async calculate(event) {
      event.stopImmediatePropagation();
      event.preventDefault();

      this.$store.dispatch('daycounter/create', this.calculation);

      let data = await this.$store.dispatch('daycounter/store');
      this.$router.push({
        name: 'Daycounter',
        params: {
          firestore_id: data.firestore_id
        }
      });
    },
    async load() {
      this.categories = await this.$services['category'].getAll();
      this.globalDaycounters = this.$services['dayCounter'].getAll({global: true});

      const tmp = this.categories.filter(c => c.key === 'birthday' || c.key === 'celebrity').map(c => c.id);

      let filter = [];
      //birthdays

      tmp.forEach(c => {
        const t = {};
        t[c] = true;
        filter.push(t);
      })
      filter.push({start_day: this.day});
      filter.push({start_month: this.month});
      this.birthdays = this.$services['dayCounter'].getAll(filter);

      //holidays
      filter = [];
      const h = {};
      h[this.categories.find(c => c.key === 'vacation').id] = true;
      filter.push(h);
      filter.push({frontpage: true});
      filter.push({start:format(this.date, 'yyyy-MM-dd'),operator: '>='});
      this.holidays = await this.$services['dayCounter'].getAll(filter, [{start: 'asc'}]);
    },
    setDates() {
      this.start = new Date();
      this.date = new Date();

      this.day = format(this.date,'dd');
      this.month = format(this.date,'MM');
      this.year = format(this.date,'yyyy');
    }
  },
  mounted() {
    this.setDates();
    this.load();
  }
}
</script>

<style scoped>

</style>