<template>
  <div v-on="$listeners">
    <p>URL</p>
    <BaseInput v-model="data.url" />

    <div>
      <label><input name="image_position" type="radio" v-model="data.image_position" :value="0"> Afbeelding links</label><br />
      <label><input name="image_position" type="radio" v-model="data.image_position" :value="1"> Afbeelding rechts</label>
    </div>

  </div>
</template>

<script>
export default {
  name: "SettingsImageText",
  props: {
    data: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>