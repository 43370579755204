<template>
  <div>
    <div class="w-full m-auto divide-y">
      <div>
        <div class="text-2xl text-gray-700 mb-4 border-b border-gray-200 w-4/5 text-center pb-2 mx-auto">Persoonlijke dagentellers</div>
        <div class="my-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
          <Card @reload="load" v-for="daycounter in daycounters" :key="daycounter.id" :daycounter="daycounter"></Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/daycounter/Card";

import {formatDate} from "@/plugins";

export default {
  name: "AccountDaycounter",
  components: {Card},
  data() {
    return {
      daycounters: []
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const user = this.$store.getters['user/getUser'];
      this.$services['dayCounter'].getAll({userId: user.id }).then(r => {
        this.daycounters = r;
        this.daycounters.map(d => {
          d.start.date = formatDate(d?.start?.date);
          d.end.date = formatDate(d?.end?.date);
        });
      });
    }
  }
}
</script>