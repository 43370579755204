import Vue from "vue";

import isDate from "date-fns/isDate";
import isValid from "date-fns/isValid";
import format from "date-fns/format";

import nl from "date-fns/locale/nl";
// eslint-disable-next-line

Vue.filter('format', function (v, f = 'yyyy-mm-dd') {
    if (!v) return '';

    if (isDate(v) && isValid(v)) {
        return format(v, f, { locale: nl });
    }

    return '';
});