import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAt, faCalendarAlt,
    faArrowsAlt, faTrashAlt, faStar,  faXmark, faClock, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons"
import {
    faSort,  faTrash, faCaretUp, faStar as fasStar, faCaretDown, faLock, faShareNodes, faCalculator, faCalendarDay, faChevronLeft, faChevronsLeft, faChevronRight, faChevronsRight, faCheck, faTimes, faPlus, faMinus, faEllipsisV,faCalendar,faBars,
} from '@fortawesome/pro-solid-svg-icons'
import { faGoogle, faMicrosoft, faTwitter, faApple, faFacebook } from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'

library.add(faTriangleExclamation, faStar, fasStar, faCalculator, faAt, faLock, faCalendarAlt, faCalendar, faCalendarDay, faChevronLeft, faChevronsLeft, faChevronRight, faChevronsRight,
    faArrowsAlt, faTrash, faTrashAlt, faSort, faCaretUp, faCaretDown, faBars, faXmark, faEllipsisV, faPlus, faMinus, faClock, faCheck, faTimes, faShareNodes,
    faGoogle, faMicrosoft, faTwitter, faApple, faFacebook)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("font-awesome-layers", FontAwesomeLayers);